import React, {useEffect} from 'react'
import {Button, Card, Col, Form, Input, Layout, Modal, Row, Typography} from "antd";
import {inputRequired, min6} from "../../utils/forms.utils";
import {useDispatch, useSelector} from "react-redux";
import {selectUser} from "../../store/auth/auth.selector";
import {useIsLoading} from "../../store/app/loading/loading.hooks";
import {actionTypes} from "../../store/users/users.types";
import {changePassword, editProfile} from "../../store/users/users.actions";
import {useHasErrors} from "../../store/app/error/error.hooks";

const {Title} = Typography

const Profile = () => {

  const user = useSelector(selectUser)
  const [dataForm] = Form.useForm()
  const [passForm] = Form.useForm()
  const [modal, contextHolder] = Modal.useModal()
  const dispatch = useDispatch()
  const [updateLoading, updateFinished] = useIsLoading([actionTypes.EDIT_PROFILE])
  const [pwdLoading, pwdFinished] = useIsLoading([actionTypes.CHANGE_PASSWORD])
  const [pwdError, pwdHasError] = useHasErrors([actionTypes.CHANGE_PASSWORD])

  useEffect(() => {
    dataForm.setFieldsValue({
      firstName: user?.firstName,
      lastName: user?.lastName,
    })
  }, [dataForm, user])


  useEffect(() => {
    if (updateFinished) {
      modal.success({
        title: '¡Éxito!',
        content: 'Datos guardados exitosamente'
      })
    }
  }, [modal, updateFinished])

  useEffect(() => {
    if (pwdFinished) {
      if (pwdHasError) {
        modal.error({
          title: '¡Uh-oh!',
          content: pwdError.message || 'Ocurrió un error, intente más tarde'
        })
      } else {
        modal.success({
          title: '¡Éxito!',
          content: 'Datos guardados exitosamente'
        })
      }
    }
  }, [modal, pwdError, pwdFinished, pwdHasError])

  const update = ({firstName, lastName}) => dispatch(editProfile({userId: user.id, firstName, lastName}))
  const changePwd = ({oldPassword, newPassword}) => dispatch(changePassword({oldPassword, newPassword}))
  return (
    <Layout className='h-100'>
      <Row>
        <Title>Perfil de usuario</Title>
      </Row>
      <Row className='h-100' gutter={[32, 32]}>
        <Col md={12}>
          <Card className='h-100 bg-card'>
            <Form layout={'vertical'} form={dataForm} onFinish={update}>
              <Title level={4}>Datos:</Title>
              <Form.Item name='firstName' label={"Nombre"} rules={[inputRequired]}>
                <Input autoComplete='off' />
              </Form.Item>
              <Form.Item name='lastName' label={"Apellido"} rules={[inputRequired]}>
                <Input autoComplete='off' />
              </Form.Item>
              <div className='right'>
                <Button
                  size='large'
                  shape="round"
                  type="primary"
                  htmlType='submit'
                  loading={updateLoading}
                >
                  Guardar
                </Button>
              </div>
            </Form>
          </Card>
        </Col>
        <Col md={12}>
          <Card className='bg-card h-100'>
            <Form layout='vertical' form={passForm} onFinish={changePwd}>
              <Title level={4}>Cambiar contraseña</Title>
              <Form.Item name="oldPassword" label={"Contraseña actual"} rules={[inputRequired]}>
                <Input type='password' autoComplete='off'/>
              </Form.Item>
              <Form.Item name="newPassword" label={"Contraseña nueva"} rules={[inputRequired, min6]}>
                <Input type='password' autoComplete='off'/>
              </Form.Item>
              <div className='right'>
                <Button
                  size='large'
                  shape="round"
                  type="primary"
                  htmlType='submit'
                  loading={pwdLoading}
                >
                  Guardar
                </Button>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>
      {contextHolder}
    </Layout>
  )
}
export default Profile

