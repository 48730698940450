import React from 'react';
import './App.scss';
import {Provider} from 'react-redux'
import {PersistGate} from 'redux-persist/integration/react'
import {persistor, store} from "./store";
import "antd/dist/antd.css";
import "./App.scss"
import RoutesWrapper from "./routes/RoutesWrapper";
import {BrowserRouter as Router} from "react-router-dom";

function App() {


  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <RoutesWrapper/>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
