import React from 'react'
import {Menu} from "antd";
import {
  FileSearchOutlined,
  LogoutOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  UsergroupAddOutlined,
  UserOutlined,
  AppstoreOutlined
} from '@ant-design/icons';
import {Link, useHistory, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "../store/auth/logout.actions";
import {selectUserRoles} from "../store/auth/auth.selector";

const options = [
  {key: "1", name: "Tiendas", icon: <ShopOutlined/>, path: "/", admin: false},
  {key: "2", name: "Pedidos", icon: <ShoppingCartOutlined/>, path: "/ecwid/ordenes", admin: false},
  {key: "3", name: "Productos", icon: <AppstoreOutlined/>, path: "/productos"},
  {key: "4", name: "Usuarios", icon: <UsergroupAddOutlined/>, path: "/usuarios", admin: true},
  {key: "5", name: "Reportes", icon: <FileSearchOutlined/>, path: "/reportes", admin: true},
  {key: "6", name: "Perfil", icon: <UserOutlined/>, path: "/perfil"},
]

const SideMenu = () => {

  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const userRoles = useSelector(selectUserRoles)
  const isAdmin = userRoles.find(r => r.name === "Admin") != null

  const pages = isAdmin ? options : options.filter(op => op.admin === false)

  const doLogout = () => {
    dispatch(logout())
    history.replace("/")
  }

  const currentPath = location.pathname;
  const selected = currentPath === '/' || currentPath.startsWith('/tienda') ? ['1'] :
    options.filter(o => o.path.length > 1 && currentPath.startsWith(o.path)).map(o => o.key)

  return (
    <Menu  selectedKeys={selected} mode="inline">
      {
        pages.map(option =>
          <Menu.Item key={option.key} icon={option.icon}>
            <Link to={option.path}>{option.name}</Link>
          </Menu.Item>
        )
      }
      <Menu.Item icon={<LogoutOutlined/>} onClick={doLogout}>
        Salir
      </Menu.Item>
    </Menu>
  )
}
export default SideMenu
