import React from 'react'
import {Route,Switch} from "react-router-dom";
import Login from "../pages/Auth/Login";

const PublicRoutes = () => {

    return (
        <Switch>
            <Route path={'/login'}>
                <Login/>
            </Route>
        </Switch>
    )
}
export default PublicRoutes

