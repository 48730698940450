import {$http} from "../../utils/http.utils";

class OrdersService {
  byNumber = (number) => $http.get(`/orders/${number}`)
  process = (orderIds) => $http.post(`/orders/`, {orderIds})
  summary = ()=> $http.get('/orders/summary')
  issues = ({page, pageSize})=> $http.get('/orders/issues',{params: {page, pageSize}})
  importOrders = ({orderNumber})=> $http.post('/ecwid/orders/import',{orderNumber})

  getProductsByEcwidNumber =(ecwidNumber) => $http.get(`/orders/${ecwidNumber}/products`)
}

export default new OrdersService()
