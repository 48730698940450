import {actionTypes} from './orders.types'
import {successState} from '../app/app.actions'

const initialState = {
  currentOrder: {items: []},
  processResult: null,
  summary: {pending: 0, issues:0, lastImport: {}},
  issues: {items: [], page: 1, count: 0},
  productsByEcwidNumber: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_ORDER):
      return {
        ...state,
        currentOrder: action.data
      };
    case successState(actionTypes.PROCESS_ORDERS):
      return {
        ...state,
        processResult: action.data
      };
    case successState(actionTypes.GET_SUMMARY):
      return {
        ...state,
        summary: action.data
      };
    case successState(actionTypes.GET_ISSUES):
      return {
        ...state,
        issues: action.data
      };
    case successState(actionTypes.GET_PRODUCTS_BY_ECWID_NUMBER):
      return {
        ...state,
        productsByEcwidNumber: action.data
      };
    case successState(actionTypes.CLEAR_ORDERS):
      return initialState
    default:
      return state || initialState
  }
}

export default reducer
