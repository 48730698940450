import ProductsService from './products.service'
import {executeAction} from "../app/app.actions";
import {actionTypes} from "./products.types";

export const getAllProducts = ({page = 1, pageSize = 25, search} = {}) => dispatch => {
  const process = () => ProductsService.allProducts({page, pageSize, search});
  dispatch(executeAction(actionTypes.GET_ALL_PRODUCTS, process))
}

export const getInventoryByBranch = ({page = 1, pageSize = 25, branchId, search}) => dispatch => {
  const process = () => ProductsService.inventoryByBranch({page, pageSize, search, branchId});
  dispatch(executeAction(actionTypes.GET_INVENTORY_BY_BRANCH, process))
}

export const getInventoryByBranchAndSKU = (branchId, sku) => dispatch => {
  const process = () => ProductsService.inventoryByBranchAndSKU(branchId, sku);
  dispatch(executeAction(actionTypes.GET_INVENTORY_BY_BRANCH_AND_SKU, process))
}

export const getInventoryByBranchAndProductList = ({page = 1, pageSize = 25, branchId, products}) => dispatch => {
  const process = async () => {
    const existences = []
    for (const product of products) {
      const response = await ProductsService.inventoryByBranch({page, pageSize, search: product, branchId})
      existences.push(response.data.items[0] || {available: 0});
    }
    return existences;
  }
  dispatch(executeAction(actionTypes.GET_INVENTORY_BY_BRANCH_AND_PRODUCT_LIST, process))
}

export const createProduct = ({sku, name, description, price}) => dispatch => {
  const process = async () => ProductsService.createProduct({sku, name, description, price})
  dispatch(executeAction(actionTypes.CREATE_PRODUCT, process))
}

export const getUploadTemplate = () => dispatch => {
  const process = async () => ProductsService.getUploadTemplate()
  dispatch(executeAction(actionTypes.DOWNLOAD_TEMPLATE, process))
}

export const uploadProducts = (file) => dispatch => {
  const process = async () => ProductsService.uploadProducts({file})
  dispatch(executeAction(actionTypes.UPLOAD_PRODUCTS, process))
}
