import {$http, downloadFile} from "../../utils/http.utils";

class InventoryService {
  sale =
    ({branchId, products, client, comment}) =>
      $http.post(`/inventory/${branchId}/sale`, {products, client, comment});

  returnProducts =
    ({branchId, products, comment}) =>
      $http.post(`/inventory/${branchId}/return`, {products, comment});

  egressProducts =
    ({branchId, products, comment}) =>
      $http.post(`/inventory/${branchId}/egress`, {products, comment});

  ingressProducts =
    ({branchId, products, comment}) =>
      $http.post(`/inventory/${branchId}/ingress`, {products, comment});

  downloadTransactionPdf = ({branchId, transactionId}) =>
    downloadFile(`/inventory/${branchId}/transaction/${transactionId}/pdf`);

  downloadInventoryReport =
    ({branch1, branch2}) =>
      downloadFile('/inventory/report', {branch1, branch2, compare: branch1 != null && branch2 != null})

  downloadSalesReport =
    ({from, to, branchId}) =>
      downloadFile('/inventory/report/sales', {from, to, branchId})

  incomingTransfers =
    ({branchId, pageSize, page, pending, processed}) =>
      $http.get(`/transfers/incoming/branch/${branchId}`, {params: {pageSize, page, pending, processed}})

  outgoingTransfers =
    ({branchId, pageSize, page, pending, processed}) =>
      $http.get(`/transfers/outgoing/branch/${branchId}`, {params: {pageSize, page, pending, processed}})

  createTransfer =
    ({from, to, products, comment}) =>
      $http.post(`/transfers`, {from, to, products, comment})

  getTransferDetail =
    ({branchId, transferId}) =>
      $http.get(`/transfers/incoming/${transferId}/branch/${branchId}`)

  cleanup =
    ({branchId}) =>
      $http.post(`/inventory/${branchId}/cleanup`)

  confirmTransfer =
    ({transferId, products, from, to, comment}) =>
      $http.put(`/transfers/confirm/${transferId}`, {products, from, to, comment})

  getTransactions =
    ({branchId, sales, returns, reserves, ingress, egress, from, to, transfers, receipts, pageSize, page, search}) =>
      $http.get(`/inventory/${branchId}/transactions`, {
        params: {
          to,
          from,
          sales,
          returns,
          reserves,
          ingress,
          egress,
          transfers,
          receipts,
          page,
          pageSize,
          search
        }
      })

  downloadBranchTransactions =
    ({branchId, sales, returns, reserves, ingress, egress, from, to, transfers, receipts, search}) =>
      downloadFile(`/inventory/${branchId}/transactions/excel`, {
        to,
        from,
        sales,
        returns,
        reserves,
        ingress,
        egress,
        transfers,
        receipts,
        search
      })

  downloadGeneralTransactions =
    ({sales, returns, reserves, ingress, egress, from, to, transfers, receipts, search}) =>
      downloadFile(`/inventory/transactions/excel`, {
        to,
        from,
        sales,
        returns,
        reserves,
        ingress,
        egress,
        transfers,
        receipts,
        search
      })

  downloadTemplate = () => downloadFile('/inventory/upload/template')
}

export default new InventoryService()
