import AuthService from './auth.service'
import {executeAction} from "../app/app.actions";
import {actionTypes} from "./auth.types";
import {$http} from "../../utils/http.utils";

export const login = (email, password) => dispatch => {
  const process = async () => {
    const result = await AuthService.login(email, password);
    const data = result.data;
    $http.defaults.headers.common['Authorization'] = `bearer ${data.access_token}`;
    return result
  }
  dispatch(executeAction(actionTypes.LOGIN, process))
}

export const getCurrentUser = () => dispatch =>{
  const process = ()=> AuthService.current()
  dispatch(executeAction(actionTypes.CURRENT_USER, process))
}
