import React, {useEffect} from 'react'
import './Login.scss'
import {Button, Card, Col, Form, Input, Modal, Row} from "antd";
import {ReactComponent as Logo} from "../../components/svg/logo.svg";
import {UnlockOutlined, UserOutlined} from '@ant-design/icons'
import {inputRequired, typeEmail} from "../../utils/forms.utils";
import {useDispatch} from "react-redux";
import {login} from "../../store/auth/auth.actions";
import {useIsLoading} from "../../store/app/loading/loading.hooks";
import {actionTypes} from "../../store/auth/auth.types";
import {useHasErrors} from "../../store/app/error/error.hooks";
import {useHistory} from "react-router-dom";

const Login = () => {

  const history = useHistory()
  const dispatch = useDispatch()
  const [modal, contextHolder] = Modal.useModal()
  const [loading, finished] = useIsLoading([actionTypes.LOGIN])
  const [APIError, hasError] = useHasErrors([actionTypes.LOGIN])

  const onFinish = ({email, password}) => {
    dispatch(login(email, password))
  }

  useEffect(() => {
    if (finished) {
      if (hasError) {
        modal.error({title: '¡Uh-Oh!', content: APIError.message})
      } else {
        history.replace("/")
      }
    }
  }, [loading, hasError, finished, modal, APIError.message, history])

  return (
    <>
      <Row justify='center' align='middle' className='login-content'>
        <Col xxl={4} md={6} sm={12} xs={20}>
          <Row justify='center' align='middle'>
            <Logo/>
          </Row>
          <Row justify='center' align='middle'>
            <h1>Inventario Vivo</h1>
          </Row>
          <Card>
            <Form size='large' name="login-form" onFinish={onFinish}>
              <Form.Item name="email" rules={[inputRequired, typeEmail]}>
                <Input
                  placeholder="Correo electrónico"
                  prefix={<UserOutlined className="site-form-item-icon"/>}
                />
              </Form.Item>
              <Form.Item name="password" rules={[inputRequired]}>
                <Input
                  placeholder='Contraseña'
                  type='password'
                  prefix={<UnlockOutlined className="site-form-item-icon"/>}
                />
              </Form.Item>
              <Form.Item>
                <Button block type="primary" htmlType="submit" loading={!!loading} disabled={!!loading}>
                  Ingresar
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
      {contextHolder}
    </>
  )
}
export default Login


