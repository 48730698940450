const currencyFormatter = new Intl.NumberFormat('en-US', {
  // style: 'currency',
  // currency: 'GTQ',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const currencyFormat = value => isNaN(value) ? '': currencyFormatter.format(value)

