export const actionTypes = {
  SALE: 'inventory/sale',
  RETURN: 'inventory/return',
  INGRESS: 'inventory/ingress',
  EGRESS: 'inventory/egress',
  INVENTORY_REPORT: 'inventory/report/get',
  SALES_REPORT: 'inventory/report/sales/get',
  INVENTORY_COMPARE_REPORT: 'inventory/report/compare/get',
  INCOMING_TRANSFERS: 'inventory/transfers/incoming/get',
  OUTGOING_TRANSFERS: 'inventory/transfers/outgoing/get',
  CREATE_TRANSFER: 'inventory/transfers/create',
  TRANSFER_DETAIL: 'inventory/transfer/detail/get',
  CONFIRM_TRANSFER: 'inventory/transfer/confirm',
  GET_TRANSACTIONS:'inventory/transactions/get',
  DOWNLOAD_TRANSACTION_PDF: 'inventory/pdf/get',
  DOWNLOAD_BRANCH_TRANSACTIONS: 'inventory/branch/transactions/excel/get',
  DOWNLOAD_GENERAL_TRANSACTIONS: 'inventory/general/transactions/excel/get',
  CLEAR_BRANCH_INVENTORY: 'inventory/branch/clean',
  DOWNLOAD_TEMPLATE: 'inventory/upload/template',
  CLEAR_INVENTORY: 'inventory/clear'
}
