import {combineReducers} from 'redux'
import loading from './app/loading/loading.reducer'
import error from './app/error/error.reducer'
import auth from './auth/auth.reducer'
import branches from './branches/branches.reducer'
import products from './products/products.reducer'
import inventory from './inventory/inventory.reducer'
import orders from './orders/orders.reducer'
import users from './users/users.reducer'

export const rootReducer = combineReducers({
  loading,
  error,
  auth,
  branches,
  products,
  inventory,
  orders,
  users,
})
