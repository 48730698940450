import React, {useEffect} from 'react'
import {Button, Card, Checkbox, Col, DatePicker, Form, Input, Layout, Pagination, Row, Tooltip, Typography} from "antd";
import {currencyFormat} from "../../utils/format.utils";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {selectCurrentBranch} from "../../store/branches/branches.selector";
import {DownloadOutlined, SearchOutlined} from '@ant-design/icons'
import './Transactions.scss'
import {selectTransactions} from "../../store/inventory/inventory.selector";
import {
  downloadBranchTransactions,
  downloadTransactionPDF,
  getTransactions
} from "../../store/inventory/inventory.actions";
import {clearActionResult} from "../../store/app/app.actions";
import {actionTypes} from "../../store/inventory/inventory.types";
import {getBranchById} from "../../store/branches/branches.actions";
import moment from "moment";
import {useIsLoading} from "../../store/app/loading/loading.hooks";

const {Title} = Typography;
const dateFormat = 'DD/MM/YYYY';

const Transactions = () => {

  const {branchId} = useParams()
  const dispatch = useDispatch()
  const branch = useSelector(selectCurrentBranch)
  const transactions = useSelector(selectTransactions)
  const [searchForm] = Form.useForm()
  const [loading] = useIsLoading([actionTypes.DOWNLOAD_BRANCH_TRANSACTIONS])

  const init = () => {
    dispatch(getBranchById(branchId))
    dispatch(getTransactions({branchId, pageSize: 10}))
    return () => clearActionResult(actionTypes.GET_TRANSACTIONS)
  }
  useEffect(init, [])

  const refresh = ({search, sales, returns, reserves, ingress, egress, transfers, receipts, from, to, page, pageSize}) => {

    if (search != null || sales != null || returns != null || reserves != null || ingress != null || egress != null || transfers != null || receipts != null || from != null || to != null) {
      page = 1
    }

    sales = searchForm.getFieldValue('sales');
    returns = searchForm.getFieldValue('returns');
    reserves = searchForm.getFieldValue('reserves');
    ingress = searchForm.getFieldValue('ingress');
    egress = searchForm.getFieldValue('egress');
    transfers = searchForm.getFieldValue('transfers');
    receipts = searchForm.getFieldValue('receipts');
    from = searchForm.getFieldValue('from');
    to = searchForm.getFieldValue('to');

    dispatch(getTransactions({
      search,
      sales,
      returns,
      reserves,
      ingress,
      egress,
      transfers,
      receipts,
      from: from?.unix(),
      to: to?.unix(),
      branchId,
      pageSize: pageSize || transactions.pageSize,
      page: page || transactions.page,
    }))
  }

  const downloadTransactions = () => {

    const sales = searchForm.getFieldValue('sales');
    const returns = searchForm.getFieldValue('returns');
    const reserves = searchForm.getFieldValue('reserves');
    const ingress = searchForm.getFieldValue('ingress');
    const egress = searchForm.getFieldValue('egress');
    const transfers = searchForm.getFieldValue('transfers');
    const receipts = searchForm.getFieldValue('receipts');
    const from = searchForm.getFieldValue('from');
    const to = searchForm.getFieldValue('to');
    const search = searchForm.getFieldValue('search');

    dispatch(downloadBranchTransactions({
      search,
      sales,
      returns,
      reserves,
      ingress,
      egress,
      transfers,
      receipts,
      from: from?.unix(),
      to: to?.unix(),
      branchId,
    }))
  }

  const onPageChange = (page, pageSize) => refresh({page, pageSize})

  const download = (transactionId) => dispatch(downloadTransactionPDF({branchId, transactionId}))

  return (
    <Layout className={'h-100'}>
      <Row justify='space-between' align='center'>
        <Title>{branch?.name} - Transacciones</Title>
        <Link to={`/tienda/${branchId}/transacciones/reportes`} >
          <Title level={2} className='pointer blue underline'>Extras</Title>
        </Link>
      </Row>
      <Row className='h-100' gutter={[32, 32]}>
        <Col md={24}>
          <Card className="bg-card h-100">
            <Row gutter={[16, 16]}>
              <Form onFinish={refresh} layout={'inline'} onValuesChange={refresh} form={searchForm}>
                <Row className='w-100'>
                  <Form.Item name='search'>
                    <Input placeholder="# de transacción" type='search' autoComplete='off' prefix={<SearchOutlined/>}/>
                  </Form.Item>
                  <Form.Item name='from' label='Desde:' initialValue={moment().subtract(1, 'd')}>
                    <DatePicker type='date' format={dateFormat}/>
                  </Form.Item>
                  <Form.Item name='to' label='Hasta:' initialValue={moment()}>
                    <DatePicker format={dateFormat}/>
                  </Form.Item>
                  <div className='right flex-1'>
                    <Button
                      size='large'
                      shape="round"
                      type="primary"
                      loading={loading}
                      onClick={downloadTransactions}
                      icon={<DownloadOutlined/>}
                    >
                      Descargar Reporte
                    </Button>
                  </div>
                </Row>

                <Row>
                  <Form.Item name='sales' valuePropName="checked" initialValue={true}>
                    <Checkbox>Ventas</Checkbox>
                  </Form.Item>
                  <Form.Item name='returns' valuePropName="checked" initialValue={true}>
                    <Checkbox>Retornos </Checkbox>
                  </Form.Item>
                  <Form.Item name='reserves' valuePropName="checked" initialValue={true}>
                    <Checkbox>Reservas</Checkbox>
                  </Form.Item>
                  <Form.Item name='ingress' valuePropName="checked" initialValue={true}>
                    <Checkbox>Ingresos</Checkbox>
                  </Form.Item>
                  <Form.Item name='egress' valuePropName="checked" initialValue={true}>
                    <Checkbox>Salidas</Checkbox>
                  </Form.Item>
                  <Form.Item name='transfers' valuePropName="checked" initialValue={true}>
                    <Checkbox>Transferencias</Checkbox>
                  </Form.Item>
                  <Form.Item name='receipts' valuePropName="checked" initialValue={true}>
                    <Checkbox>Recepción</Checkbox>
                  </Form.Item>
                </Row>

              </Form>
            </Row>

            <Row className='product-list-header'>
              <Col md={4}>Fecha</Col>
              <Col md={3}>Número</Col>
              <Col md={4}>Ecwid #</Col>
              <Col md={10}>Descripción</Col>
              <Col md={2}>Total Q.</Col>
              <Col md={2}/>
            </Row>
            {
              transactions?.items?.map((tx, index) =>
                <Row className='product-list-item' key={`tx-${tx.id}`} onClick={() => download(tx.id)}>
                  <Col
                    md={4}>{new Date(tx.date).toLocaleDateString('es-GT')} {new Date(tx.date).toLocaleTimeString('es-GT')}</Col>
                  <Col md={3}>{tx.number}</Col>
                  <Col md={4}>{tx.ecwidOrderNumber || '---'}</Col>
                  <Col md={9}>{tx.description}</Col>
                  <Col md={2} className='right'>{currencyFormat(tx.total) || '---'}</Col>
                  <Col md={2} className='center'><Tooltip title='Descargar'><DownloadOutlined/></Tooltip></Col>
                </Row>
              )
            }
            <br/>
            <Pagination
              defaultCurrent={1}
              total={transactions?.total || 0}
              onChange={onPageChange}
              current={transactions?.page}
              pageSize={transactions?.pageSize || 10}
              hideOnSinglePage
            />
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}
export default Transactions

