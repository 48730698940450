import {$http} from "../../utils/http.utils";

class UsersService {
  getUsers =
    ({page, pageSize, active, deleted}) =>
      $http.get('/users', {params: {page, pageSize, active, deleted}})

  updateUser =
    ({userId, firstName, lastName, role}) =>
      $http.put(`/users/${userId}`, {firstName, lastName, role})

  createUser =
    ({email, firstName, lastName, roles}) =>
      $http.post("/users", {email, firstName, lastName, roles})

  toggleUser =
    ({userId}) =>
      $http.delete(`/users/${userId}`)

  changePassword =
    ({oldPassword, newPassword}) =>
      $http.post(`/users/password/change`, {oldPassword, newPassword})
}

export default new UsersService()
