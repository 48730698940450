import OrdersService from './orders.service'
import {executeAction} from "../app/app.actions";
import {actionTypes} from "./orders.types";

export const getOrderByNumber = (number) => dispatch => {
  number = number.toLowerCase()
    .replace("order-", '')
    .replace("order'", '')

  const process = () => OrdersService.byNumber(number);
  dispatch(executeAction(actionTypes.GET_ORDER, process))
}

export const processOrders = (orderIds) => dispatch => {
  const process = async () => {
    const result = await OrdersService.process(orderIds);
    dispatch(getOrdersSummary())
    return result;
  }
  dispatch(executeAction(actionTypes.PROCESS_ORDERS, process))
}

export const getOrdersSummary = () => dispatch => {
  const process = () => OrdersService.summary();
  dispatch(executeAction(actionTypes.GET_SUMMARY, process))
}

export const getOrdersIssues = ({page = 1, pageSize = 25}) => dispatch => {
  const process = () => OrdersService.issues({page, pageSize});
  dispatch(executeAction(actionTypes.GET_ISSUES, process))
}

export const getProductsByEcwidNumber = (ecwidNumber) => dispatch => {
  const process = () => OrdersService.getProductsByEcwidNumber(ecwidNumber);
  dispatch(executeAction(actionTypes.GET_PRODUCTS_BY_ECWID_NUMBER, process))
}

export const importOrders = (orderNumber) => dispatch => {
  const process = async () => {
    try {
      await OrdersService.importOrders({orderNumber});
    } catch (e) {
      console.log(e)
    }
    dispatch(getOrdersSummary())
  }
  dispatch(executeAction(actionTypes.IMPORT_ORDERS, process))
}
