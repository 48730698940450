import React, {useEffect, useState} from 'react'
import {Card, Checkbox, Col, Layout, Pagination, Row, Typography} from "antd";
import './Transactions.scss'
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  downloadTransactionPDF,
  getIncomingTransfers,
  getOutgoingTransfers
} from "../../store/inventory/inventory.actions";
import {clearActionResult} from "../../store/app/app.actions";
import {actionTypes} from "../../store/inventory/inventory.types";
import {selectIncomingTransfers, selectOutgoingTransfers} from "../../store/inventory/inventory.selector";
import {DownloadOutlined} from '@ant-design/icons'
import {selectCurrentBranch} from "../../store/branches/branches.selector";
import {getBranchById} from "../../store/branches/branches.actions";

const {Title} = Typography;

const TransfersDashboard = () => {
  const {branchId} = useParams()
  const dispatch = useDispatch()
  const incoming = useSelector(selectIncomingTransfers)
  const outgoing = useSelector(selectOutgoingTransfers)
  const branch = useSelector(selectCurrentBranch)

  const [pendingIncoming, setPendingIncoming] = useState(true)
  const [processedIncoming, setProcessedIncoming] = useState(false)
  const [pendingOutgoing, setPendingOutgoing] = useState(true)
  const [processedOutgoing, setProcessedOutgoing] = useState(false)

  const init = () => {
    dispatch(getBranchById(branchId))
    return () => {
      clearActionResult(actionTypes.INCOMING_TRANSFERS)
      clearActionResult(actionTypes.OUTGOING_TRANSFERS)
    }
  }
  useEffect(init, [])

  useEffect(() => {
    dispatch(getIncomingTransfers({branchId, pending: pendingIncoming, processed: processedIncoming}))
  }, [branchId, dispatch, pendingIncoming, processedIncoming])

  useEffect(() => {
    dispatch(getOutgoingTransfers({branchId, pending: pendingOutgoing, processed: processedOutgoing}))
  }, [branchId, dispatch, pendingOutgoing, processedOutgoing])


  const onIncomingPageChange = (page, pageSize) => dispatch(getIncomingTransfers({
    branchId,
    page,
    pageSize,
    pending: pendingIncoming,
    processed: processedIncoming
  }))

  const onOutgoingPageChange = (page, pageSize) => dispatch(getOutgoingTransfers({
    branchId,
    page,
    pageSize,
    pending: pendingOutgoing,
    processed: processedOutgoing
  }))

  const download = (branchId, transactionId) => dispatch(downloadTransactionPDF({branchId, transactionId}))

  return (
    <Layout className={'h-100'}>
      <Title level={2}>{branch?.name} - Transferencias</Title>
      <Row className={'h-100'} gutter={[32, 32]}>
        <Col md={9}>
          <Card className={'bg-card h-100'}>
            <Row className='transaction-shared-title'>
              <Title level={3}>Entrantes</Title>
              <div>
                <Checkbox checked={pendingIncoming} onChange={() => setPendingIncoming(!pendingIncoming)}>
                  Pendientes
                </Checkbox>
                <Checkbox checked={processedIncoming} onChange={() => setProcessedIncoming(!processedIncoming)}>
                  Procesadas
                </Checkbox>
              </div>
            </Row>
            <Row className='product-list-header'>
              <Col md={6}>Fecha</Col>
              <Col md={8}>#</Col>
              <Col md={10}>Desde</Col>
            </Row>
            {
              incoming.items.map(transfer =>
                <Link to={`/tienda/${branchId}/transferencias/recibir/${transfer.id}`} key={`incoming-${transfer.id}`}>
                  <Row className={`product-list-item ${transfer.processed ? 'green' : ''}`}
                  >
                    <Col md={5}>{new Date(transfer.createdAt).toLocaleDateString("es-GT")}</Col>
                    <Col md={8}>{transfer.transactionNumber}</Col>
                    <Col md={10}>{transfer.branchName}</Col>
                  </Row>
                </Link>
              )
            }
            <Pagination
              defaultCurrent={1}
              total={incoming?.items?.total || 0}
              onChange={onIncomingPageChange}
              current={incoming?.page}
              pageSize={incoming?.pageSize || 10}
              hideOnSinglePage
            />
          </Card>
        </Col>

        <Col md={9}>
          <Card className={'bg-card h-100'}>
            <Row className='transaction-shared-title'>
              <Title level={3}>Salientes</Title>
              <div>
                <Checkbox checked={pendingOutgoing} onChange={() => setPendingOutgoing(!pendingOutgoing)}>
                  Pendientes
                </Checkbox>
                <Checkbox checked={processedOutgoing} onChange={() => setProcessedOutgoing(!processedOutgoing)}>
                  Procesadas
                </Checkbox>
              </div>
            </Row>
            <Row className='product-list-header'>
              <Col md={6}>Fecha</Col>
              <Col md={8}>#</Col>
              <Col md={10}>Hacia</Col>
            </Row>
            {
              outgoing.items?.map(transfer =>
                <Row
                  key={`outgoing-${transfer.id}`}
                  onClick={() => download(transfer.branchId, transfer.transactionId)}
                  className={`product-list-item ${transfer.processed ? 'green' : ''}`}
                >
                  <Col md={5}>{new Date(transfer.createdAt).toLocaleDateString("es-GT")}</Col>
                  <Col md={8}>{transfer.transactionNumber}</Col>
                  <Col md={9}>{transfer.branchName}</Col>
                  <Col><DownloadOutlined/></Col>
                </Row>
              )
            }
            <br/>
            <Pagination
              defaultCurrent={1}
              total={outgoing?.items?.total || 0}
              onChange={onOutgoingPageChange}
              current={outgoing?.page}
              pageSize={outgoing?.pageSize || 10}
              hideOnSinglePage
            />
          </Card>
        </Col>

        <Col md={6} className='transaction-vertical-buttons'>
          <Link to={`/tienda/${branchId}/transferencias/nueva`} className="custom-card button">Nueva
            Transferencia</Link>
        </Col>
      </Row>
    </Layout>
  )
}
export default TransfersDashboard

