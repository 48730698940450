import React, {useEffect, useState} from 'react'
import {Button, Card, Col, Form, Input, Layout, Modal, Pagination, Row, Typography} from "antd";
import {currencyFormat} from "../../utils/format.utils";
import {useDispatch, useSelector} from "react-redux";
import {selectAllProducts} from "../../store/products/products.selector";
import {createProduct, getAllProducts} from "../../store/products/products.actions";
import {SearchOutlined} from '@ant-design/icons'
import {inputRequired} from "../../utils/forms.utils";
import {useIsLoading} from "../../store/app/loading/loading.hooks";
import {actionTypes} from "../../store/products/products.types";
import {useHasErrors} from "../../store/app/error/error.hooks";
import '../Store/Transactions.scss'
import {Link} from "react-router-dom";


const {Title} = Typography

const Products = () => {

  const [search, setSearch] = useState("")
  const products = useSelector(selectAllProducts)
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [modal, contextHolder] = Modal.useModal()
  const [loading, finished] = useIsLoading([actionTypes.CREATE_PRODUCT])
  const [APIError, hasError] = useHasErrors([actionTypes.CREATE_PRODUCT])

  const onPageChange = (page, pageSize) => dispatch(getAllProducts({page, pageSize, search}))
  const onFinishSearch = ({search}) => {
    setSearch(search)
    dispatch(getAllProducts({
      search,
      page: products.page,
      pageSize: products.pageSize,
    }))
  }

  useEffect(() => {
    if (finished) {
      if (hasError) {
        modal.error({
          title: '¡Uh-oh!',
          content: APIError.message || "Ocurrio un error, por favor intente más tarde"
        })
      } else {
        modal.success({
          title: '¡Éxito!',
          content: "Producto guardado exitosamente"
        })
        form.resetFields()
      }
    }
  }, [APIError, finished, form, hasError, modal])

  useEffect(() => {
    dispatch(getAllProducts({pageSize: 10, page: 1}))
  }, [dispatch])

  const create = ({sku, name, description, price}) => dispatch(createProduct({sku, name, description, price}))

  return (
    <Layout className='h-100'>
      <Row>
        <Title>Productos</Title>
      </Row>
      <Row className='h-100' gutter={[32, 32]}>
        <Col md={16}>
          <Card className='bg-card h-100'>
            <Form onFinish={onFinishSearch}>
              <Form.Item name='search'>
                <Input
                  autoFocus
                  type='search'
                  autoComplete='off'
                  prefix={<SearchOutlined/>}
                  placeholder="Buscar Productos"
                />
              </Form.Item>
              <Row className='product-list-header'>
                <Col md={4}>SKU</Col>
                <Col md={4}>Cant.</Col>
                <Col md={12}>Producto</Col>
                <Col md={4} className='center'>Precio</Col>
              </Row>
              {
                products?.items?.map((product, index) =>
                  <Row className='product-list-item' key={`product-added-${index}`}>
                    <Col md={4}>{product.sku}</Col>
                    <Col md={4} className='center'>{product.quantity}x</Col>
                    <Col md={12}>{product.name}</Col>
                    <Col md={1}>Q. </Col>
                    <Col md={3} className='center'>{currencyFormat(product.price)}</Col>
                  </Row>
                )
              }
            </Form>
            <br/>
            <Pagination
              defaultCurrent={1}
              total={products?.total || 0}
              onChange={onPageChange}
              current={products?.page}
              pageSize={products?.pageSize || 10}
              hideOnSinglePage
            />
          </Card>
        </Col>
        <Col md={8}>
          <Card className='bg-card'>
            <Title level={2}>Crear Producto</Title>
            <Form layout='vertical' onFinish={create} form={form}>
              <Form.Item name="sku" rules={[inputRequired]}>
                <Input placeholder="SKU" autoComplete='off'/>
              </Form.Item>
              <Form.Item name="name" rules={[inputRequired]}>
                <Input placeholder="Nombre" autoComplete='off'/>
              </Form.Item>
              <Form.Item name="description" rules={[inputRequired]}>
                <Input placeholder="Descripción" autoComplete='off'/>
              </Form.Item>
              <Form.Item name="price" rules={[inputRequired]}>
                <Input type='number' placeholder="Precio" autoComplete='off'/>
              </Form.Item>

              <div className='right'>
                <Button
                  size='large'
                  shape="round"
                  type="primary"
                  htmlType='submit'
                  loading={loading}
                >
                  Guardar
                </Button>
              </div>
            </Form>
          </Card>
          <Link to={'/productos/crear'}>
            <Button className={'bg-card orders-process-button'} onClick={process} disabled={loading}>
              Crear de forma masiva
            </Button>
          </Link>
        </Col>
      </Row>
      {contextHolder}
    </Layout>
  )
}
export default Products

