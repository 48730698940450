const audioContext =new AudioContext()

const beep = (vol, freq, duration) =>{
  const v=audioContext.createOscillator()
  const u=audioContext.createGain()
  v.connect(u)
  v.frequency.value=freq
  v.type="square"
  u.connect(audioContext.destination)
  u.gain.value=vol*0.01
  v.start(audioContext.currentTime)
  v.stop(audioContext.currentTime+duration*0.001)
}

export const playErrorSound = ()=>{
  beep(999,220,300)
}

export const textToAudio = (message) => {
  let speech = new SpeechSynthesisUtterance();
  speech.lang = "es-GT";

  speech.text = message;
  speech.volume = 1;
  speech.rate = 1.3;
  speech.pitch = 0.7;

  window.speechSynthesis.speak(speech);
}
