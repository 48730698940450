export const actionTypes = {
  GET_ALL_BRANCHES: 'branches/all/get',
  GET_BRANCH_BY_ID: 'branches/id/get',
  GET_BRANCHES_BY_USER: 'branches/user/get',
  GET_PRINCIPAL_BRANCH: 'branches/principal/get',
  GET_ASSIGNED_USERS: 'branches/users/assigned/get',
  ADD_USER_TO_BRANCH: 'branches/users/assigned/add',
  REMOVE_USER_FROM_BRANCH: 'branches/users/assigned/remove',
  ACTIVATE_BRANCH: 'branches/activate',
  DEACTIVATE_BRANCH: 'branches/deactivate',
  SET_PRINCIPAL_BRANCH: 'branches/principal/set',
  CREATE_BRANCH: 'branches/create',
  CLEAR_BRANCHES: 'branches/clear',
}
