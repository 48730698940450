import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Link, Prompt, useParams} from "react-router-dom"
import {Button, Card, Col, Form, Input, Layout, Modal, Row, Select, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getBranchById} from "../../store/branches/branches.actions";
import {clearActionResult} from "../../store/app/app.actions";
import {actionTypes} from "../../store/branches/branches.types";
import {actionTypes as productActionTypes} from "../../store/products/products.types";
import {actionTypes as inventoryActionTypes} from "../../store/inventory/inventory.types";
import {selectAllBranches, selectCurrentBranch} from "../../store/branches/branches.selector";
import {getAllProducts, getInventoryByBranchAndSKU} from "../../store/products/products.actions";
import {selectProductBySKU} from "../../store/products/products.selector";
import {CloseOutlined, SearchOutlined} from '@ant-design/icons'
import {useIsLoading} from "../../store/app/loading/loading.hooks";
import './Transactions.scss'
import {useHasErrors} from "../../store/app/error/error.hooks";
import {inputRequired} from "../../utils/forms.utils";
import {createTransfer} from "../../store/inventory/inventory.actions";
import {selectCurrentTransaction} from "../../store/inventory/inventory.selector";
import {playErrorSound} from "../../utils/sound.utils";

const {Option} = Select;
const {Title} = Typography;
const {TextArea} = Input;

const TransferCreate = () => {
  const {branchId} = useParams()
  const dispatch = useDispatch()
  const [quantity, setQuantity] = useState(1)
  const [added, setAdded] = useState([])
  const branch = useSelector(selectCurrentBranch)
  const productBySKU = useSelector(selectProductBySKU)
  const currentTransfer = useSelector(selectCurrentTransaction)
  const branches = useSelector(selectAllBranches)
  const filteredBranches = (branches.items || []).filter(branch => branch.id !== parseInt(branchId))

  const [, searchFinished] = useIsLoading([productActionTypes.GET_INVENTORY_BY_BRANCH_AND_SKU])
  const [searchAPIError, searchHasError] = useHasErrors([productActionTypes.GET_INVENTORY_BY_BRANCH_AND_SKU])

  const [transferIsLoading, transferFinished] = useIsLoading([inventoryActionTypes.CREATE_TRANSFER])
  const [transferAPIError, transferHasError] = useHasErrors([inventoryActionTypes.CREATE_TRANSFER])

  const [modal, contextHolder] = Modal.useModal()
  const searchRef = useRef()
  const [searchForm] = Form.useForm()
  const [returnsForm] = Form.useForm()


  const init = () => {
    dispatch(getAllProducts(1))
    dispatch(getBranchById(branchId))
    return () => clearActionResult(actionTypes.GET_BRANCH_BY_ID)
  }
  useEffect(init, [])

  const addToList = useCallback((product) => {
    setAdded([{...product, quantity}, ...added])
    setQuantity(1)
    dispatch(clearActionResult(productActionTypes.GET_INVENTORY_BY_BRANCH_AND_SKU))
  }, [added, dispatch, quantity])

  const findOnCart = useCallback(
    product => added
      .filter(item => item.sku === product.sku)
      .reduce((acc, current) => acc + current.quantity, 0)
    , [added])

  useEffect(() => {
    if (searchFinished) {
      if (searchHasError) {
        modal.error({title: '¡Uh-Oh!', content: searchAPIError.message})
        playErrorSound();
      } else {
        if (productBySKU != null && productBySKU.length === 1) {
          const onCart = findOnCart(productBySKU[0])
          const total = quantity + onCart
          if (total <= productBySKU[0].available) {
            addToList({...productBySKU[0], quantity})
          } else {
            modal.error({
              title: productBySKU[0].name,
              content: `Usted solicitó ${quantity}x pero solo hay ${productBySKU[0].available} disponibles\n
              Actualmente tiene ${onCart} agregados en esta orden`
            })
            playErrorSound();
          }
          setQuantity(1)
          dispatch(clearActionResult(productActionTypes.GET_INVENTORY_BY_BRANCH_AND_SKU))
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFinished, productBySKU, searchHasError, searchAPIError.message])

  const onFinishSearch = ({search}) => {
    const split = search.split("*")
    if (split.length === 2 && !isNaN(split[0])) {
      dispatch(getInventoryByBranchAndSKU(branchId, split[1]))
      setQuantity(parseInt(split[0]))
    } else {
      dispatch(getInventoryByBranchAndSKU(branchId, search))
    }
    searchForm.resetFields()
    searchRef.current.focus()
  }

  const initiallInfo = () => {
    modal.warning({title: '¡Cuidado!', content: 'Recuerda pedir autorización para ejecutar esta acción'})
    playErrorSound()
  }
  useEffect(initiallInfo, [])

  const onFinishSale = ({comment, toBranch}) => {
    const products = added.map(product => ({
      productId: product.id,
      quantity: product.quantity,
    }))
    dispatch(createTransfer({from: branchId, to: toBranch, products, comment}))
  }

  useEffect(() => {
    if (transferFinished) {
      if (transferHasError) {
        modal.error({title: '¡Uh-Oh!', content: transferAPIError.message})
        playErrorSound();
      } else {
        modal.success({title: currentTransfer.number, content: '¡Transferencia creada exitosamente!'})
        dispatch(clearActionResult(inventoryActionTypes.INGRESS))
        returnsForm.resetFields()
        setAdded([])
      }
    }
  }, [currentTransfer, dispatch, modal, transferAPIError, transferFinished, transferHasError, returnsForm])

  const remove = index => {
    added.splice(index, 1)
    setAdded([...added])
  }

  return (
    <Layout className={'h-100'}>
      <Prompt
        when={added.length > 0}
        message='No has terminado la transacción, ¿Estás seguro que desas salir?'
      />
      <Row>
        <Link to={`/tienda/${branchId}`}>
          <Title>{branch?.name}</Title>
        </Link>
      </Row>
      <Row className='h-100' gutter={[32, 32]}>
        <Col md={16}>
          <Card className="bg-card h-100">
            <Title level={2}>Crear Transferencia</Title>
            <Form onFinish={onFinishSearch} form={searchForm}>
              <Form.Item name='search'>
                <Input
                  autoFocus
                  type='search'
                  ref={searchRef}
                  autoComplete='off'
                  prefix={<SearchOutlined/>}
                  placeholder="Buscar Productos"
                />
              </Form.Item>
              <Row className='product-list-header'>
                <Col md={3}>SKU</Col>
                <Col md={3}>Cant.</Col>
                <Col md={17}>Producto</Col>
                <Col md={1}/>
              </Row>
              {
                added.map((product, index) =>
                  <Row className='product-list-item' key={`product-added-${index}`}>
                    <Col md={3}>{product.sku}</Col>
                    <Col md={3} className='center'>{product.quantity}x</Col>
                    <Col md={17}>{product.name}</Col>
                    <Col md={1}>
                      <Button type='danger' size='small' icon={<CloseOutlined/>} onClick={() => remove(index)}/>
                    </Col>
                  </Row>
                )
              }
            </Form>
          </Card>
        </Col>
        <Col md={8} className='sale-right-panel'>
          <Card className='bg-card'>
            <Title level={2}>Hacia la bodega:</Title>
            <Row gutter={[32]}>
              <Form size='large' className='sales-form' onFinish={onFinishSale} form={returnsForm}>
                <Form.Item name="toBranch" rules={[inputRequired]}>
                  <Select>
                    {
                      filteredBranches.map(branch =>
                        <Option value={branch.id} key={`branches-${branch.id}`}>{branch.name}</Option>
                      )
                    }
                  </Select>
                </Form.Item>
                <Form.Item name="comment">
                  <TextArea
                    rows={10}
                    placeholder="Comentarios..."
                  />
                </Form.Item>
              </Form>
            </Row>
          </Card>
          <Card className='bg-card'>
            <Row gutter={[32]}>
              <Col span={8}>
                <Title level={2} className='total-title'>Total:</Title>
              </Col>
              <Col span={16}>
                <Title level={2} className='total-title right'>
                  {added.reduce((acc, p) => acc + p.quantity, 0)} Artículos
                </Title>
              </Col>
            </Row>
            <Row gutter={[32]} justify='center'>
              <Button
                size='large'
                shape="round"
                type="primary"
                loading={transferIsLoading}
                disabled={added.length === 0}
                onClick={() => returnsForm.submit()}
              >
                Guardar
              </Button>
            </Row>
          </Card>
        </Col>
      </Row>
      {contextHolder}
    </Layout>
  )
}
export default TransferCreate

