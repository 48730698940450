import { actionTypes } from './auth.types'
import { successState } from '../app/app.actions'

const initialState = {
  accessToken: null,
  user: null,
  branches: [],
  roles: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.LOGIN):
      return action.data;
    case successState(actionTypes.CURRENT_USER):
      return {
        ...state,
        user: action.data.user,
        branches: action.data.branches,
      }
    case successState(actionTypes.LOGOUT):
      return initialState;
    default:
      return state || initialState
  }
}

export default reducer
