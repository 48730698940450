import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Link, Prompt, useParams} from "react-router-dom"
import {Button, Card, Col, Form, Input, Layout, Modal, Row, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getBranchById} from "../../store/branches/branches.actions";
import {clearActionResult} from "../../store/app/app.actions";
import {actionTypes} from "../../store/branches/branches.types";
import {actionTypes as productActionTypes} from "../../store/products/products.types";
import {actionTypes as inventoryActionTypes} from "../../store/inventory/inventory.types";
import {selectCurrentBranch} from "../../store/branches/branches.selector";
import {getAllProducts, getInventoryByBranchAndSKU} from "../../store/products/products.actions";
import {selectProductBySKU} from "../../store/products/products.selector";
import {CloseOutlined, SearchOutlined} from '@ant-design/icons'
import {useIsLoading} from "../../store/app/loading/loading.hooks";
import './Transactions.scss'
import {useHasErrors} from "../../store/app/error/error.hooks";
import {currencyFormat} from "../../utils/format.utils";
import {sale} from "../../store/inventory/inventory.actions";
import {selectCurrentTransaction} from "../../store/inventory/inventory.selector";
import {playErrorSound} from "../../utils/sound.utils";

const {Title} = Typography;
const {TextArea} = Input;

const Sales = () => {
  const {branchId} = useParams()
  const dispatch = useDispatch()
  const [quantity, setQuantity] = useState(1)
  const [added, setAdded] = useState([])
  const branch = useSelector(selectCurrentBranch)
  const productBySKU = useSelector(selectProductBySKU)
  const currentSale = useSelector(selectCurrentTransaction)

  const [, searchFinished] = useIsLoading([productActionTypes.GET_INVENTORY_BY_BRANCH_AND_SKU])
  const [searchAPIError, searchHasError] = useHasErrors([productActionTypes.GET_INVENTORY_BY_BRANCH_AND_SKU])

  const [saleIsLoading, saleFinished] = useIsLoading([inventoryActionTypes.SALE])
  const [saleAPIError, saleHasError] = useHasErrors([inventoryActionTypes.SALE])

  const [modal, contextHolder] = Modal.useModal()
  const searchRef = useRef()
  const [searchForm] = Form.useForm()
  const [salesForm] = Form.useForm()


  const init = () => {
    dispatch(getAllProducts())
    dispatch(getBranchById(branchId))
    return () => clearActionResult(actionTypes.GET_BRANCH_BY_ID)
  }
  useEffect(init, [])

  const addToList = useCallback((product) => {
    setAdded([{...product, quantity}, ...added])
    setQuantity(1)
    dispatch(clearActionResult(productActionTypes.GET_INVENTORY_BY_BRANCH_AND_SKU))
  }, [added, dispatch, quantity])

  const findOnCart = useCallback(
    product => added
      .filter(item => item.sku === product.sku)
      .reduce((acc, current) => acc + current.quantity, 0)
    , [added])

  useEffect(() => {
    if (searchFinished) {
      if (searchHasError) {
        modal.error({title: '¡Uh-Oh!', content: searchAPIError.message})
        playErrorSound();
      } else {
        if (productBySKU != null && productBySKU.length === 1) {
          const onCart = findOnCart(productBySKU[0])
          const total = quantity + onCart
          if (total <= productBySKU[0].available) {
            addToList({...productBySKU[0], quantity})
          } else {
            modal.error({
              title: productBySKU[0].name,
              content: `Usted solicitó ${quantity}x pero solo hay ${productBySKU[0].available} disponibles\n
              Actualmente tiene ${onCart} agregados en esta orden`
            })
            playErrorSound();
          }
          setQuantity(1)
          dispatch(clearActionResult(productActionTypes.GET_INVENTORY_BY_BRANCH_AND_SKU))
        }
      }
    }
  }, [searchFinished, productBySKU, addToList, searchHasError, modal, searchAPIError.message, quantity, dispatch, findOnCart])

  const onFinishSearch = ({search}) => {
    const split = search.split("*")
    if (split.length === 2 && !isNaN(split[0])) {
      dispatch(getInventoryByBranchAndSKU(branchId, split[1]))
      setQuantity(parseInt(split[0]))
    } else {
      dispatch(getInventoryByBranchAndSKU(branchId, search))
    }
    searchForm.resetFields()
    searchRef.current.focus()
  }

  const onFinishSale = ({client, comment}) => {
    const products = added.map(product => ({
      productId: product.id,
      quantity: product.quantity,
    }))
    dispatch(sale({branchId, products, client, comment}))
  }

  useEffect(() => {
    if (saleFinished) {
      if (saleHasError) {
        modal.error({title: '¡Uh-Oh!', content: saleAPIError.message})
        playErrorSound();
      } else {
        modal.success({title: currentSale.number, content: '¡Orden creada exitosamente!'})
        dispatch(clearActionResult(inventoryActionTypes.SALE))
        salesForm.resetFields()
        setAdded([])
      }
    }
  }, [currentSale, dispatch, modal, saleAPIError, saleFinished, saleHasError, salesForm])

  const remove = index => {
    added.splice(index,1)
    setAdded([...added])
  }

  return (
    <Layout className={'h-100'}>
      <Prompt
        when={added.length > 0}
        message='No has terminado la transacción, ¿Estás seguro que desas salir?'
      />
      <Row>
        <Link to={`/tienda/${branchId}`}>
          <Title>{branch?.name}</Title>
        </Link>
      </Row>
      <Row className='h-100' gutter={[32, 32]}>
        <Col md={16}>
          <Card className="bg-card h-100">
            <Title level={2}>Venta</Title>
            <Form onFinish={onFinishSearch} form={searchForm}>
              <Form.Item name='search'>
                <Input
                  autoFocus
                  type='search'
                  ref={searchRef}
                  autoComplete='off'
                  prefix={<SearchOutlined/>}
                  placeholder="Buscar Productos"
                />
              </Form.Item>
              <Row className='product-list-header'>
                <Col md={3}>SKU</Col>
                <Col md={3}>Cant.</Col>
                <Col md={9}>Producto</Col>
                <Col md={3} className='center'>Precio</Col>
                <Col md={2}/>
                <Col md={3}>Total</Col>
                <Col md={1}/>
              </Row>
              {
                added.map((product, index) =>
                  <Row className='product-list-item' key={`product-added-${index}`}>
                    <Col md={3}>{product.sku}</Col>
                    <Col md={3} className='center'>{product.quantity}x</Col>
                    <Col md={9}>{product.name}</Col>
                    <Col md={1}>Q. </Col>
                    <Col md={2} className='center'>{currencyFormat(product.price)}</Col>
                    <Col md={1}/>
                    <Col md={1}>Q. </Col>
                    <Col md={3} className='center'>{currencyFormat(product.price * product.quantity)}</Col>
                    <Col md={1}>
                      <Button type='danger' size='small' icon={<CloseOutlined/>} onClick={() => remove(index)}/>
                    </Col>
                  </Row>
                )
              }
            </Form>
          </Card>
        </Col>
        <Col md={8} className='sale-right-panel'>
          <Card className='bg-card'>
            <Title level={2}>Datos</Title>
            <Row gutter={[32]}>
              <Form size='large' className='sales-form' onFinish={onFinishSale} form={salesForm}>
                <Form.Item name="client">
                  <Input placeholder="Nombre de cliente"/>
                </Form.Item>
                <Form.Item name="comment">
                  <TextArea rows={7} placeholder="Comentarios..." />
                </Form.Item>
              </Form>
            </Row>
          </Card>
          <Card className='bg-card'>
            <Row>
              <Col span={24}>
                <Title level={2} className='right'>
                  {added.reduce((acc, p) => acc + p.quantity, 0)} Artículos
                </Title>
              </Col>
            </Row>
            <Row gutter={[32]}>
              <Col span={8}>
                <Title level={2} className='total-title'>Total:</Title>
              </Col>
              <Col span={16}>
                <Title level={2} className='total-title right'>
                  Q. {currencyFormat(added.reduce((acc, p) => acc + p.price * p.quantity, 0))}
                </Title>
              </Col>
            </Row>
            <Row gutter={[32]} justify='center'>
              <Button
                size='large'
                shape="round"
                type="primary"
                loading={saleIsLoading}
                disabled={added.length === 0}
                onClick={() => salesForm.submit()}
              >
                Finalizar Venta
              </Button>
            </Row>
          </Card>
        </Col>
      </Row>
      {contextHolder}
    </Layout>
  )
}
export default Sales

