import React, {useEffect, useState} from 'react'
import './Dashboard.scss'
import {Card, Checkbox, Col, Row, Typography} from 'antd';
import {AppstoreAddOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from "react-redux";
import {selectUserBranches} from "../../store/branches/branches.selector";
import {branchesByUser, getAllBranches} from "../../store/branches/branches.actions";
import {Link} from "react-router-dom";
import {selectUser, selectUserRoles} from "../../store/auth/auth.selector";
import CreateBranchModal from "./CreateBranchModal";

const {Title} = Typography;

const Dashboard = () => {

  const dispatch = useDispatch()
  const branches = useSelector(selectUserBranches) || []
  const user = useSelector(selectUser)
  const [showModal, setShowModal] = useState(false)
  const [active, setActive] = useState(true)
  const [deleted, setDeleted] = useState(false)
  const userRoles = useSelector(selectUserRoles)
  const isAdmin = userRoles.find(r => r.name === "Admin") != null

  const init = () => {
    dispatch(branchesByUser(user?.id))
    dispatch(getAllBranches())
  }
  useEffect(init, [])
  const filteredBranches = branches != null && Array.isArray(branches) ?
    branches.filter(b => (b.deleted && deleted) || (!b.deleted && active))
    : []

  return (
    <>
      <Row>
        <Title>Hola {user?.firstName} {user?.lastName}</Title>
      </Row>
      <Row className='w-100' justify={'space-between'}>
        <Title level={5}>Selecciona una tienda para operar:</Title>
        {
          isAdmin &&
          <div>
            Ver bodegas: &nbsp;
            <Checkbox checked={active} onChange={() => setActive(!active)}>
              Activas
            </Checkbox>
            <Checkbox checked={deleted} onChange={() => setDeleted(!deleted)}>
              No Activas
            </Checkbox>
          </div>
        }
      </Row>
      <Row gutter={[16, 16]}>
        {
          filteredBranches.map(branch =>
            <Col xxl={4} md={8} sm={12} xs={24} key={`branch-${branch.id}`}>
              <Link to={`/tienda/${branch.id}`}>
                <Card
                  className={`custom-card ${branch.deleted ? 'deleted-branch' : ''}`}
                  // actions={[
                  //   <SettingOutlined key="setting"/>,
                  //   <EditOutlined key="edit"/>,
                  //   <EllipsisOutlined key="ellipsis"/>,
                  // ]}
                >
                  <h2>{branch.name}</h2>
                </Card>
              </Link>
            </Col>
          )
        }
        {
          isAdmin &&
          <>
            <Col xxl={4} md={8} sm={12} xs={24} onClick={() => setShowModal(true)}>
              <Row justify={'center'}>
                <AppstoreAddOutlined className="huge-icon button green"/>
              </Row>
              <Row justify={'center'} className='green pointer'>
                <Title level={3} className='green'>Nueva Bodega</Title>
              </Row>
            </Col>
            <CreateBranchModal visible={showModal} onCancel={() => setShowModal(false)}/>
          </>
        }

      </Row>
    </>

  )
}
export default Dashboard

