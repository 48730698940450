import InventoryService from './inventory.service'
import {executeAction} from "../app/app.actions";
import {actionTypes} from "./inventory.types";

export const sale = ({branchId, products, client, comment}) => dispatch => {
  const process = async () => {
    const result = await InventoryService.sale({branchId, products, client, comment});
    await InventoryService.downloadTransactionPdf({branchId, transactionId: result.data.id})
    return result
  }
  dispatch(executeAction(actionTypes.SALE, process))
}

export const returnProducts = ({branchId, products, comment}) => dispatch => {
  const process = async () => {
    const result = await InventoryService.returnProducts({branchId, products, comment});
    await InventoryService.downloadTransactionPdf({branchId, transactionId: result.data.id})
    return result
  }
  dispatch(executeAction(actionTypes.RETURN, process))
}

export const specialIngress = ({branchId, products, comment}) => dispatch => {
  const process = async () => {
    const result = await InventoryService.ingressProducts({branchId, products, comment});
    await InventoryService.downloadTransactionPdf({branchId, transactionId: result.data.id})
    return result
  }
  dispatch(executeAction(actionTypes.INGRESS, process))
}

export const specialEgress = ({branchId, products, comment}) => dispatch => {
  const process = async () => {
    const result = await InventoryService.egressProducts({branchId, products, comment});
    await InventoryService.downloadTransactionPdf({branchId, transactionId: result.data.id})
    return result
  }
  dispatch(executeAction(actionTypes.EGRESS, process))
}

export const getInventoryReport = () => dispatch => {
  const process = () => InventoryService.downloadInventoryReport({});
  dispatch(executeAction(actionTypes.INVENTORY_REPORT, process))
}

export const getCompareReport = ({branch1, branch2}) => dispatch => {
  const process = () => InventoryService.downloadInventoryReport({branch1, branch2});
  dispatch(executeAction(actionTypes.INVENTORY_COMPARE_REPORT, process))
}

export const getSalesReport = ({from, to, branchId}) => dispatch => {
  const process = () => InventoryService.downloadSalesReport({from, to, branchId});
  dispatch(executeAction(actionTypes.SALES_REPORT, process))
}

export const getIncomingTransfers = ({branchId, page, pageSize, pending, processed}) => dispatch => {
  const process = () => InventoryService.incomingTransfers({branchId, page, pageSize, processed, pending});
  dispatch(executeAction(actionTypes.INCOMING_TRANSFERS, process))
}

export const getOutgoingTransfers = ({branchId, page, pageSize, pending, processed}) => dispatch => {
  const process = () => InventoryService.outgoingTransfers({branchId, page, pageSize, processed, pending});
  dispatch(executeAction(actionTypes.OUTGOING_TRANSFERS, process))
}

export const getTransferDetails = ({branchId, transferId}) => dispatch => {
  const process = () => InventoryService.getTransferDetail({branchId, transferId});
  dispatch(executeAction(actionTypes.TRANSFER_DETAIL, process))
}

export const createTransfer = ({from, to, products, comment}) => dispatch => {
  const process = async () => {
    const result = await InventoryService.createTransfer({from, to, products, comment});
    await InventoryService.downloadTransactionPdf({branchId: from, transactionId: result.data.id})
    return result
  }
  dispatch(executeAction(actionTypes.CREATE_TRANSFER, process))
}

export const downloadTransactionPDF = ({branchId, transactionId}) => dispatch => {
  const process = () => InventoryService.downloadTransactionPdf({branchId, transactionId});
  dispatch(executeAction(actionTypes.DOWNLOAD_TRANSACTION_PDF, process))
}

export const confirmTransfer = ({from, to, transferId, products, comment}) => dispatch => {
  const process = async () => {
    const result = await InventoryService.confirmTransfer({from, to, transferId, products, comment});
    await InventoryService.downloadTransactionPdf({branchId: to, transactionId: result.data.id})
    return result
  }
  dispatch(executeAction(actionTypes.CONFIRM_TRANSFER, process))
}

export const cleanInventory = ({branchId}) => dispatch => {
  const process = async () => {
    const result = await InventoryService.cleanup({branchId});
    await InventoryService.downloadTransactionPdf({branchId, transactionId: result.data.id})
    return result
  }
  dispatch(executeAction(actionTypes.CONFIRM_TRANSFER, process))
}

export const getTransactions = ({branchId, sales, returns, reserves, ingress, egress, from, to, transfers, receipts, pageSize, page, search}) => dispatch => {
  const process = () => InventoryService.getTransactions({
    branchId,
    sales,
    returns,
    reserves,
    ingress,
    egress,
    from,
    to,
    transfers,
    receipts,
    pageSize,
    page,
    search
  });
  dispatch(executeAction(actionTypes.GET_TRANSACTIONS, process))
}

export const downloadBranchTransactions = ({branchId, sales, returns, reserves, ingress, egress, from, to, transfers, receipts, search}) => dispatch => {
  const process = () => InventoryService.downloadBranchTransactions({
    branchId,
    sales,
    returns,
    reserves,
    ingress,
    egress,
    from,
    to,
    transfers,
    receipts,
    search
  });
  dispatch(executeAction(actionTypes.DOWNLOAD_BRANCH_TRANSACTIONS, process))
}

export const downloadGeneralTransactions = ({sales, returns, reserves, ingress, egress, from, to, transfers, receipts}) => dispatch => {
  const process = () => InventoryService.downloadGeneralTransactions({
    sales,
    returns,
    reserves,
    ingress,
    egress,
    from,
    to,
    transfers,
    receipts,
  });
  dispatch(executeAction(actionTypes.DOWNLOAD_GENERAL_TRANSACTIONS, process))
}

export const getInventoryUploadTemplate = () => dispatch => {
  const process = () => InventoryService.downloadTemplate();
  dispatch(executeAction(actionTypes.DOWNLOAD_TEMPLATE, process))
}

