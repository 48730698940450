import React, {useEffect, useState} from 'react'
import {Button, Card, Checkbox, Col, Layout, Modal, Row, Typography} from "antd";
import {Link, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  activateBranch,
  addUserToBranch, deactivateBranch,
  getBranchById, getPrincipalBranch,
  removeUserFromBranch, setPrincipalBranch,
  usersByBranch
} from "../../store/branches/branches.actions";
import {clearActionResult} from "../../store/app/app.actions";
import {actionTypes} from "../../store/branches/branches.types";
import {
  selectAssignedUsersByBranch,
  selectCurrentBranch,
  selectPrincipalBranch
} from "../../store/branches/branches.selector";
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {NOOP} from "../../utils/constants.utils";
import {selectUser} from "../../store/auth/auth.selector";
import '../Users/Users.scss'
import {playErrorSound} from "../../utils/sound.utils";
import {cleanInventory} from "../../store/inventory/inventory.actions";

const {Title} = Typography;


const StoreDetails = () => {

  const {branchId} = useParams()
  const branch = useSelector(selectCurrentBranch)
  const principal = useSelector(selectPrincipalBranch)
  const users = useSelector(selectAssignedUsersByBranch)
  const me = useSelector(selectUser)
  const [active, setActive] = useState(true)
  const [deleted, setDeleted] = useState(false)
  const [modal, contextHolder] = Modal.useModal()

  const dispatch = useDispatch()

  const init = () => {
    dispatch(getBranchById(branchId))
    dispatch(usersByBranch(branchId))
    dispatch(getPrincipalBranch())
    return () => {
      clearActionResult(actionTypes.GET_BRANCH_BY_ID)
      clearActionResult(actionTypes.GET_ASSIGNED_USERS)
    }
  }
  useEffect(init, [])

  const openDeactivateBranchModal = () => {
    playErrorSound();
    modal.confirm({
      title: '¡Cuidado!',
      content: 'Si desabilita la bodega nadie podrá operar en ella.',
      onOk: () => dispatch(deactivateBranch(branchId))
    })
  }

  const openActivateBranchModal = () => {
    playErrorSound();
    modal.confirm({
      title: '¡Cuidado!',
      content: 'Si habilita la bodega los usuarios asignados podrán operar en ella.',
      onOk: () => dispatch(activateBranch(branchId))
    })
  }

  const openPrincipalBranchModal = () => {
    playErrorSound();
    modal.confirm({
      title: '¡Cuidado!',
      content: `La bodega ${principal.name} es la bodega principal actual. Si continua los pedidos de ecwid serán enviados hacia esta bodega`,
      onOk: () => dispatch(setPrincipalBranch(branchId))
    })
  }

  const openDeleteInventoryModal = () => {
    playErrorSound();
    modal.confirm({
      title: '¡Cuidado!',
      content: 'Esto eliminará todo el inventario de la bodega.',
      onOk: ()=> dispatch(cleanInventory({branchId}))
    })
  }

  const filteredUsers = users.filter(u => ((!u.assigned && deleted) || (u.assigned && active)) && u.id !== me.id)
  return (
    <Layout className='h-100'>
      <Row>
        <Link to={`/tienda/${branchId}`}>
          <Title>{branch?.name}</Title>
        </Link>
      </Row>
      <Row className='h-100' gutter={[32, 32]}>
        <Col md={16}>
          <Card className="bg-card h-100">
            <Row justify={'space-between'}>
              <Title level={2}>Usuarios</Title>
              <div>
                <Checkbox checked={active} onChange={() => setActive(!active)}>
                  Asignados
                </Checkbox>
                <Checkbox checked={deleted} onChange={() => setDeleted(!deleted)}>
                  No Asignados
                </Checkbox>
              </div>
            </Row>
            <Row className='user-list-header'>
              <Col md={9}>Email</Col>
              <Col md={10}>Nombre</Col>
              <Col md={2}>Asignado</Col>
              <Col md={3}/>
            </Row>
            {
              filteredUsers.map(user =>
                <Row className='user-list-item' key={`user-${user.id}`} onClick={NOOP}>
                  <Col md={9}>{user.email}</Col>
                  <Col md={10}>{user.firstName} {user.lastName}</Col>
                  <Col md={2}>{user?.assigned ? <CheckOutlined className='green'/> :
                    <CloseOutlined className='red'/>}</Col>
                  <Col md={3}>
                    {
                      user?.assigned ?
                        <Button danger onClick={() => dispatch(removeUserFromBranch({branchId, userId: user.id}))}
                                block>
                          Remover
                        </Button> :
                        <Button type='primary' onClick={() => dispatch(addUserToBranch({branchId, userId: user.id}))}
                                block>
                          Asignar
                        </Button>
                    }
                  </Col>
                </Row>
              )
            }
          </Card>
        </Col>
        <Col md={8}>
          <Card className="bg-card h-100">
            <Title level={2}>Acciones</Title>
            <Row>
              {
                !branch?.principal ?
                  <Button type="primary" block onClick={openPrincipalBranchModal}>
                    Marcar como bodega principal
                  </Button> :
                  <p>Esta es la bodega principal</p>
              }
            </Row>
            <br/>
            <Row>
              {
                branch?.principal ?
                  <p>No puede desabilitar la bodega principal</p> :
                  branch?.deleted ?
                    <Button type="primary" block onClick={openActivateBranchModal}>
                      Habilitar Almacén
                    </Button> :
                    <Button danger block onClick={openDeactivateBranchModal}>
                      Desabilitar Almacén
                    </Button>
              }
            </Row>
            <br/>
            <Row>
              <Link to={`/tienda/${branchId}/detalles/inventario`} className='w-100'>
                <Button type='primary' block>
                  Subir inventario
                </Button>
              </Link>
            </Row>
            <br/>
            <Row>
              <Button danger block onClick={openDeleteInventoryModal}>
                Vaciar Almacén
              </Button>
            </Row>
          </Card>
        </Col>
      </Row>
      {contextHolder}
    </Layout>
  )
}
export default StoreDetails
