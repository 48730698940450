import {actionTypes} from './inventory.types'
import {successState} from '../app/app.actions'

const initialState = {
  currentTransaction: null,
  incomingTransfers: {items: [], page: 1, pageSize: 10},
  outgoingTransfers: {items: [], page: 1, pageSize: 10},
  transactions: {items: [], page: 1, pageSize: 10},
  currentTransfer: null
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.SALE):
    case successState(actionTypes.RETURN):
    case successState(actionTypes.INGRESS):
    case successState(actionTypes.EGRESS):
    case successState(actionTypes.CREATE_TRANSFER):
      return {
        ...state,
        currentTransaction: action.data
      };
    case successState(actionTypes.INCOMING_TRANSFERS):
      return {
        ...state,
        incomingTransfers: action.data
      };
    case successState(actionTypes.OUTGOING_TRANSFERS):
      return {
        ...state,
        outgoingTransfers: action.data
      };
    case successState(actionTypes.GET_TRANSACTIONS):
      return {
        ...state,
        transactions: action.data
      };
    case successState(actionTypes.TRANSFER_DETAIL):
      return {
        ...state,
        currentTransfer: action.data
      };
    case successState(actionTypes.CLEAR_INVENTORY):
      return initialState
    default:
      return state || initialState
  }
}

export default reducer
