import React, {useEffect} from 'react'
import {Route, Switch, useHistory} from "react-router-dom";
import PublicRoutes from "./PublicRoutes";
import ProtectedRoutes from "./ProtectedRoutes";
import {selectAccessToken} from "../store/auth/auth.selector";
import {useSelector} from "react-redux";

const RoutesWrapper = () => {

  const token = useSelector(selectAccessToken)
  const history = useHistory()
  useEffect(() => {
    if (token == null) {
      history.replace('/login')
    }
  }, [history, token])

  return (
    <Switch>
      <Route path={'/login'}>
        <PublicRoutes/>
      </Route>
      <Route path={'/'}>
        <ProtectedRoutes/>
      </Route>
    </Switch>
  )
}
export default RoutesWrapper
