import React, {useEffect, useRef, useState} from 'react'
import {Button, Card, Col, Form, Input, Layout, Modal, Row, Typography} from "antd";
import './Orders.scss'
import {SearchOutlined} from '@ant-design/icons'
import {useDispatch, useSelector} from "react-redux";
import {getOrderByNumber, getOrdersSummary, importOrders, processOrders} from "../../store/orders/orders.actions";
import {selectCurrentOrder, selectOrdersSummary} from "../../store/orders/orders.selector";
import {useIsLoading} from "../../store/app/loading/loading.hooks";
import {actionTypes} from "../../store/orders/orders.types";
import {currencyFormat} from "../../utils/format.utils";
import {playErrorSound, textToAudio} from "../../utils/sound.utils";
import {useHasErrors} from "../../store/app/error/error.hooks";
import {Link} from "react-router-dom";

const {Title} = Typography;

const EcwidOrders = () => {
  const searchRef = useRef()
  const [searchForm] = Form.useForm()

  const [orders, setOrders] = useState([])
  const dispatch = useDispatch()
  const currentOrder = useSelector(selectCurrentOrder)
  const summary = useSelector(selectOrdersSummary)
  const [, searchFinished] = useIsLoading([actionTypes.GET_ORDER])
  const [searchAPIError, searchHasError] = useHasErrors([actionTypes.GET_ORDER])

  const [loading, finished] = useIsLoading([actionTypes.PROCESS_ORDERS])
  const [APIError, hasError] = useHasErrors([actionTypes.PROCESS_ORDERS])

  const [importLoading] = useIsLoading([actionTypes.IMPORT_ORDERS])

  const [modal, contextHolder] = Modal.useModal()

  const init = () => {
    dispatch(getOrdersSummary())
  }
  useEffect(init, [])

  useEffect(() => {
    if (searchFinished) {
      if (searchHasError) {
        modal.error({title: '¡Uh-Oh!', content: searchAPIError.message})
        playErrorSound()
      } else {
        textToAudio(currentOrder.totalItems)
        const all = [...orders, currentOrder]
        const unique = Array.from(new Set(all.map(order => order.id)))
          .map(id => all.find(a => a.id === id)
          )
        setOrders(unique)
      }
    }
  }, [currentOrder, modal, orders, searchAPIError, searchFinished, searchHasError])

  useEffect(() => {
    if (finished) {
      if (hasError) {
        modal.error({title: '¡Uh-Oh!', content: APIError.message})
        playErrorSound()
      } else {
        modal.success({title: 'Exito', content: 'Pedidos procesados exitosamente'})
      }
      setOrders([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [APIError.message, finished, hasError])

  const onSearchFinish = ({search}) => {
    if (search.length > 0) {
      dispatch(getOrderByNumber(search))
      searchForm.resetFields()
      searchRef.current.focus()
    }
  }

  const process = () => {
    dispatch(processOrders(orders.map(order => order.id)))
  }

  const importOrdersNow = () => dispatch(importOrders())

  return (
    <Layout className={'h-100'}>
      <Title level={2}>Ecwid - Pedidos</Title>
      <Row className={'h-100'} gutter={[32, 32]}>
        <Col md={16}>
          <Card className="bg-card h-100">
            <Form onFinish={onSearchFinish} form={searchForm}>
              <Form.Item name='search'>
                <Input
                  autoFocus
                  type='search'
                  ref={searchRef}
                  autoComplete='off'
                  prefix={<SearchOutlined/>}
                  placeholder="Buscar Ordenes"
                />
              </Form.Item>
            </Form>
            <Row className='orders-list-header'>
              <Col md={3} className='center'>#</Col>
              <Col md={6}>Fecha</Col>
              <Col md={4}>Pedido #</Col>
              <Col md={4}>Productos</Col>
              <Col md={6} className='center'>Monto</Col>
            </Row>
            {
              orders.map((order, index) =>
                <Row className='order-list-item' key={`order-${index}`}>
                  <Col md={3} className='center'>{index + 1}</Col>
                  <Col md={6}>{new Date(order.orderDate).toLocaleString('es-GT')}</Col>
                  <Col md={4}>{order.ecwidOrder}</Col>
                  <Col md={4}>{order.totalItems}</Col>
                  <Col md={1}>Q.</Col>
                  <Col md={5} className='center'>{currencyFormat(order.total)}</Col>
                </Row>
              )
            }
          </Card>
        </Col>
        <Col md={8}>
          <Card className={'bg-card'}>
            <Row>
              <Col md={12}>
                <Title level={5}>Ultima Actualización</Title>
              </Col>
              <Col md={12}>
                <Title level={5}>{new Date(summary.lastImport).toLocaleString('es-GT')}</Title>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Title level={5}>Pendientes</Title>
              </Col>
              <Col md={12}>
                <Title level={5}>{summary.pending}</Title>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Title level={5} type='danger'>Problemas</Title>
              </Col>
              <Col md={6}>
                <Title level={5} type='danger'>{summary.issues}</Title>
              </Col>
              <Col md={6}>
                <Link to={'/ecwid/ordenes/problemas'}>
                  <Title level={5} type='danger'>Detalles</Title>
                </Link>
              </Col>
            </Row>
            <hr/>
            <Row>
              <Col md={12}>
                <Title level={4}>Total</Title>
              </Col>
              <Col md={12}>
                <Title level={4}>{summary.pending + summary.issues}</Title>
              </Col>
            </Row>
            <br/>
            <Row>
              <Col md={24} className='right'>
                <Button
                  size='large'
                  shape="round"
                  type="primary"
                  loading={importLoading}
                  onClick={importOrdersNow}
                >
                  Importar Ahora
                </Button>
              </Col>
            </Row>
          </Card>
          <Button className={'bg-card orders-process-button'} onClick={process} disabled={loading}>
            Procesar ordenes
          </Button>
        </Col>
      </Row>
      {contextHolder}
    </Layout>
  )
}
export default EcwidOrders

