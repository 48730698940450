import BranchesService from './branches.service'
import {executeAction} from "../app/app.actions";
import {actionTypes} from "./branches.types";
import {selectUser} from "../auth/auth.selector";

export const getAllBranches = () => dispatch => {
  const process = () => BranchesService.allBranches();
  dispatch(executeAction(actionTypes.GET_ALL_BRANCHES, process))
}

export const getBranchById = (id) => dispatch => {
  const process = () => BranchesService.byId(id);
  dispatch(executeAction(actionTypes.GET_BRANCH_BY_ID, process))
}

export const getPrincipalBranch = () => dispatch => {
  const process = () => BranchesService.principal();
  dispatch(executeAction(actionTypes.GET_PRINCIPAL_BRANCH, process))
}

export const branchesByUser = (userId)=> dispatch =>{
  const process = () => BranchesService.byUser(userId);
  dispatch(executeAction(actionTypes.GET_BRANCHES_BY_USER, process))
}

export const createBranch = ({name, access=[], principal=false}) => (dispatch, getState) => {
  const userId = selectUser(getState()).id
  const process = async () => {
    await BranchesService.create({name, access, principal})
    return BranchesService.byUser(userId);
  }
  dispatch(executeAction(actionTypes.CREATE_BRANCH, process))
}

export const usersByBranch = (branchId)=> dispatch =>{
  const process = () => BranchesService.assignedUsers({branchId});
  dispatch(executeAction(actionTypes.GET_ASSIGNED_USERS, process))
}

export const addUserToBranch = ({branchId, userId})=> dispatch =>{
  const process = () => BranchesService.assignUser({branchId, userId});
  dispatch(executeAction(actionTypes.ADD_USER_TO_BRANCH, process))
}

export const removeUserFromBranch = ({branchId, userId})=> dispatch =>{
  const process = () => BranchesService.removeUser({branchId, userId});
  dispatch(executeAction(actionTypes.REMOVE_USER_FROM_BRANCH, process))
}

export const activateBranch = (branchId)=> dispatch =>{
  const process = () => BranchesService.activate({branchId});
  dispatch(executeAction(actionTypes.ACTIVATE_BRANCH, process))
}

export const deactivateBranch = (branchId)=> dispatch =>{
  const process = () => BranchesService.deactivate({branchId});
  dispatch(executeAction(actionTypes.DEACTIVATE_BRANCH, process))
}

export const setPrincipalBranch = (branchId)=> dispatch =>{
  const process = async () => {
    const response = await BranchesService.makePrincipal({branchId});
    dispatch(getBranchById(branchId))
    return response
  }
  dispatch(executeAction(actionTypes.SET_PRINCIPAL_BRANCH, process))
}
