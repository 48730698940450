import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Link, Prompt, useParams} from "react-router-dom"
import {Button, Card, Col, Form, Input, Layout, Modal, Row, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getBranchById} from "../../store/branches/branches.actions";
import {clearActionResult} from "../../store/app/app.actions";
import {actionTypes} from "../../store/branches/branches.types";
import {actionTypes as productActionTypes} from "../../store/products/products.types";
import {actionTypes as inventoryActionTypes} from "../../store/inventory/inventory.types";
import {actionTypes as orderActionsTypes} from "../../store/orders/orders.types";
import {selectCurrentBranch} from "../../store/branches/branches.selector";
import {getInventoryByBranchAndSKU} from "../../store/products/products.actions";
import {selectProductBySKU} from "../../store/products/products.selector";
import {CloseOutlined, SearchOutlined} from '@ant-design/icons'
import {useIsLoading} from "../../store/app/loading/loading.hooks";
import './Transactions.scss'
import {useHasErrors} from "../../store/app/error/error.hooks";
import {currencyFormat} from "../../utils/format.utils";
import {inputRequired} from "../../utils/forms.utils";
import {returnProducts} from "../../store/inventory/inventory.actions";
import {selectCurrentTransaction} from "../../store/inventory/inventory.selector";
import {playErrorSound} from "../../utils/sound.utils";
import {getProductsByEcwidNumber} from "../../store/orders/orders.actions";
import {selectProductsByEcwidNumber} from "../../store/orders/orders.selector";


const {Title} = Typography;
const {TextArea} = Input;

const Returns = () => {
  const {branchId} = useParams()
  const dispatch = useDispatch()
  const [quantity, setQuantity] = useState(1)
  const [byEcwidOrder, setByEcwidOrder] = useState(false)
  const [added, setAdded] = useState([])
  const branch = useSelector(selectCurrentBranch)
  const productBySKU = useSelector(selectProductBySKU)
  const productsByEcwidNumber = useSelector(selectProductsByEcwidNumber)
  const currentReturn = useSelector(selectCurrentTransaction)

  const [, searchFinished] = useIsLoading([productActionTypes.GET_INVENTORY_BY_BRANCH_AND_SKU])
  const [searchAPIError, searchHasError] = useHasErrors([productActionTypes.GET_INVENTORY_BY_BRANCH_AND_SKU])


  const [, searchByEcwidFinished] = useIsLoading([orderActionsTypes.GET_PRODUCTS_BY_ECWID_NUMBER])
  const [searchByEcwidAPIError, searchByEcwidHasError] = useHasErrors([orderActionsTypes.GET_PRODUCTS_BY_ECWID_NUMBER])

  const [returnIsLoading, returnFinished] = useIsLoading([inventoryActionTypes.RETURN])
  const [returnAPIError, returnHasError] = useHasErrors([inventoryActionTypes.RETURN])

  const [modal, contextHolder] = Modal.useModal()
  const searchRef = useRef()
  const [searchForm] = Form.useForm()
  const [returnsForm] = Form.useForm()


  const init = () => {
    dispatch(getBranchById(branchId))
    return () => clearActionResult(actionTypes.GET_BRANCH_BY_ID)
  }
  useEffect(init, [])

  const addToList = useCallback((product) => {
    setAdded([{...product, quantity}, ...added])
    setQuantity(1)
    dispatch(clearActionResult(productActionTypes.GET_INVENTORY_BY_BRANCH_AND_SKU))
  }, [added, dispatch, quantity])

  useEffect(() => {
    if (searchFinished) {
      if (searchHasError) {
        modal.error({title: '¡Uh-Oh!', content: searchAPIError.message})
        playErrorSound();
        setQuantity(1)
        dispatch(clearActionResult(productActionTypes.GET_INVENTORY_BY_BRANCH_AND_SKU))
      } else {
        if (productBySKU != null && productBySKU.length === 1) {
          addToList({...productBySKU[0], quantity})
          setQuantity(1)
          dispatch(clearActionResult(productActionTypes.GET_INVENTORY_BY_BRANCH_AND_SKU))
        }
      }
      searchForm.resetFields()
      searchRef.current.focus()
    }
  }, [searchFinished, productBySKU, addToList, searchHasError, modal, searchAPIError.message, quantity, dispatch, searchForm, searchRef])

  useEffect(() => {
    if (searchByEcwidFinished) {
      if (searchByEcwidHasError) {
        if(byEcwidOrder){
          dispatch(clearActionResult(orderActionsTypes.GET_PRODUCTS_BY_ECWID_NUMBER))
          setByEcwidOrder(false)
          setAdded([])
          returnsForm.setFieldsValue({comment: ""})
        }

        const search = searchForm.getFieldValue("search")
            .toLowerCase()
            .replace("order-", '')
            .replace("order'", '')

        dispatch(getInventoryByBranchAndSKU(branchId, search))
      } else {
        if (productsByEcwidNumber != null) {
          if(productsByEcwidNumber.length > 0){
            setByEcwidOrder(true)
            setAdded([...added, ...productsByEcwidNumber])

            const search = searchForm.getFieldValue("search")
                .toLowerCase()
                .replace("order-", '')
                .replace("order'", '')
            let comment = returnsForm.getFieldValue("comment")
            if (comment){
              comment += `,${search}`
            }else {
              comment = `Retorno de orden ECWID# ${search}`
            }
            returnsForm.setFieldsValue({comment})
          }else{
            if(byEcwidOrder){
              dispatch(clearActionResult(orderActionsTypes.GET_PRODUCTS_BY_ECWID_NUMBER))
              setByEcwidOrder(false)
              setAdded([])
              returnsForm.setFieldsValue({comment: ""})
            }
            dispatch(getInventoryByBranchAndSKU(branchId, searchForm.getFieldValue("search")))
          }
        }
      }
      searchForm.resetFields()
      searchRef.current.focus()
    }
  }, [searchByEcwidFinished, productBySKU, addToList, searchHasError, modal, searchByEcwidAPIError.message, quantity, dispatch, searchForm, searchRef])


  const onFinishSearch = ({search}) => {
    search = search.toLowerCase()
        .replace("order-", '')
        .replace("order'", '')

    const split = search.split("*")
    if (split.length === 2 && !isNaN(split[0])) {
      setAdded([])
      dispatch(clearActionResult(orderActionsTypes.GET_PRODUCTS_BY_ECWID_NUMBER))
      dispatch(getInventoryByBranchAndSKU(branchId, split[1]))
      setQuantity(parseInt(split[0]))
    } else {
      dispatch(getProductsByEcwidNumber(search))
    }
  }

  const onFinishReturn = ({comment}) => {
    const products = added.map(product => ({
      productId: product.id,
      quantity: product.quantity,
    }))
    dispatch(returnProducts({branchId, products, comment}))
  }

  useEffect(() => {
    if (returnFinished) {
      if (returnHasError) {
        modal.error({title: '¡Uh-Oh!', content: returnAPIError.message})
        playErrorSound();
      } else {
        modal.success({title: currentReturn.number, content: '¡Retorno creado exitosamente!'})
        dispatch(clearActionResult(inventoryActionTypes.RETURN))
        returnsForm.resetFields()
        setAdded([])
      }
    }
  }, [currentReturn, dispatch, modal, returnAPIError, returnFinished, returnHasError, returnsForm])

  const remove = index => {
    added.splice(index, 1)
    setAdded([...added])
  }

  console.log(byEcwidOrder)

  return (
    <Layout className={'h-100'}>
      <Prompt
        when={added.length > 0}
        message='No has terminado la transacción, ¿Estás seguro que desas salir?'
      />
      <Row>
        <Link to={`/tienda/${branchId}`}>
          <Title>{branch?.name}</Title>
        </Link>
      </Row>
      <Row className='h-100' gutter={[32, 32]}>
        <Col md={16}>
          <Card className="bg-card h-100">
            <Title level={2}>Retorno</Title>
            <Form onFinish={onFinishSearch} form={searchForm}>
              <Form.Item name='search'>
                <Input
                  autoFocus
                  type='search'
                  ref={searchRef}
                  autoComplete='off'
                  prefix={<SearchOutlined/>}
                  placeholder="Buscar Productos"
                />
              </Form.Item>
              <Row className='product-list-header'>
                <Col md={3}>SKU</Col>
                <Col md={3}>Cant.</Col>
                <Col md={9}>Producto</Col>
                <Col md={3} className='center'>Precio</Col>
                <Col md={2}/>
                <Col md={3}>Total</Col>
                <Col md={1}/>
              </Row>
              {
                added.map((product, index) =>
                  <Row className='product-list-item' key={`product-added-${index}`}>
                    <Col md={3}>{product.sku}</Col>
                    <Col md={3} className='center'>{product.quantity}x</Col>
                    <Col md={9}>{product.name}</Col>
                    <Col md={1}>Q. </Col>
                    <Col md={2} className='center'>{currencyFormat(product.price)}</Col>
                    <Col md={1}/>
                    <Col md={1}>Q. </Col>
                    <Col md={3} className='center'>{currencyFormat(product.price * product.quantity)}</Col>
                    <Col md={1}>
                      <Button type='danger' size='small' icon={<CloseOutlined/>} onClick={() => remove(index)}/>
                    </Col>
                  </Row>
                )
              }
            </Form>
          </Card>
        </Col>
        <Col md={8} className='sale-right-panel'>
          <Card className='bg-card'>
            <Title level={2}>Datos</Title>
            <Row gutter={[32]}>
              <Form size='large' className='sales-form' onFinish={onFinishReturn} form={returnsForm}>
                <Form.Item name="comment" rules={[inputRequired]}>
                  <TextArea
                    rows={10}
                    placeholder="Comentarios..."
                  />
                </Form.Item>
              </Form>
            </Row>
          </Card>
          <Card className='bg-card'>
            <Row>
              <Col span={24}>
                <Title level={2} className='right'>
                  {added.reduce((acc, p) => acc + p.quantity, 0)} Artículos
                </Title>
              </Col>
            </Row>
            <Row gutter={[32]}>
              <Col span={8}>
                <Title level={2} className='total-title'>Total:</Title>
              </Col>
              <Col span={16}>
                <Title level={2} className='total-title right'>
                  Q. {currencyFormat(added.reduce((acc, p) => acc + p.price * p.quantity, 0))}
                </Title>
              </Col>
            </Row>
            <Row gutter={[32]} justify='center'>
              <Button
                size='large'
                shape="round"
                type="primary"
                loading={returnIsLoading}
                disabled={added.length === 0}
                onClick={() => returnsForm.submit()}
              >
                Finalizar Retorno
              </Button>
            </Row>
          </Card>
        </Col>
      </Row>
      {contextHolder}
    </Layout>
  )
}
export default Returns

