import {actionTypes} from './products.types'
import {successState} from '../app/app.actions'

const initialState = {
  allProducts: {items: [], page: 1, count: 0},
  inventoryByBranch: {items: [], page: 1, count: 0},
  productBySKU: [],
  inventoryByBranchAndProductList: [],
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_ALL_PRODUCTS):
      return {
        ...state,
        allProducts: action.data
      };
    case successState(actionTypes.GET_INVENTORY_BY_BRANCH):
      return {
        ...state,
        inventoryByBranch: action.data
      };
    case successState(actionTypes.GET_INVENTORY_BY_BRANCH_AND_SKU):
      return {
        ...state,
        productBySKU: action.data
      };
    case successState(actionTypes.GET_INVENTORY_BY_BRANCH_AND_PRODUCT_LIST):
      return {
        ...state,
        inventoryByBranchAndProductList: action.data
      };
    case successState(actionTypes.CLEAR_PRODUCTS):
      return initialState
    default:
      return state || initialState
  }
}

export default reducer
