import React, {useEffect} from 'react'
import {Button, Form, Input, Modal, Radio, Row, Typography} from "antd";
import {inputRequired, typeEmail} from "../../utils/forms.utils";
import {useDispatch, useSelector} from "react-redux";
import {createUser} from "../../store/users/users.actions";
import {useIsLoading} from "../../store/app/loading/loading.hooks";
import {actionTypes} from "../../store/users/users.types";
import {useHasErrors} from "../../store/app/error/error.hooks";
import {selectCurrentPwd} from "../../store/users/users.selector";
import {playErrorSound} from "../../utils/sound.utils";
import {clearActionResult} from "../../store/app/app.actions";

const {Title} = Typography;

const CreateUserModal = ({onCancel, visible}) => {
  const dispatch = useDispatch()
  const [form] = Form.useForm()
  const [modal, contextHolder] = Modal.useModal()
  const [loading, finished] = useIsLoading([actionTypes.CREATE_USER])
  const [APIError, hasError] = useHasErrors([actionTypes.CREATE_USER])
  const currentPwd = useSelector(selectCurrentPwd)

  const cancel = () => {
    form.resetFields()
    dispatch(clearActionResult(actionTypes.CREATE_USER))
    onCancel()
  }

  useEffect(() => {
    if (finished) {
      if (hasError) {
        playErrorSound()
        modal.error({
          title: "¡Uh-Oh!",
          content: APIError.message
        })
      } else if (currentPwd) {
        modal.success({
          title: "¡Exito!",
          content: `Usuario creado exitosamente, la contraseña asignada es: ${currentPwd.password}  y se recomienda cambiarla.`,
          onOk: cancel
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [APIError.message, currentPwd, finished, hasError])

  const finish = ({email, firstName, lastName, roles}) => {
    dispatch(createUser({email, firstName, lastName, roles}))
  }

  return (
    <Modal visible={visible} footer={null} onCancel={cancel} maskClosable={false} destroyOnClose>
      <Title level={3}>Crear Usuario</Title>
      <Form layout={'vertical'} onFinish={finish} form={form}>
        <Form.Item name="email" label="Email" rules={[inputRequired, typeEmail]}>
          <Input autoComplete='off' type='search'/>
        </Form.Item>
        <Form.Item name="firstName" label="Nombre" rules={[inputRequired]}>
          <Input autoComplete='off' type='search'/>
        </Form.Item>
        <Form.Item name="lastName" label="Apellido" rules={[inputRequired]}>
          <Input autoComplete='off' type='search'/>
        </Form.Item>
        <Form.Item name="roles" label="Roles" rules={[inputRequired]}>
          <Radio.Group>
            <Radio value={[1]}> Admin </Radio>
            <Radio value={[2]}> Empleado </Radio>
          </Radio.Group>
        </Form.Item>
        <Row justify={'end'}>
          <Form.Item>
            <Button type="primary" htmlType="submit" size={'large'} shape={"round"} loading={loading}>
              Guardar
            </Button>
          </Form.Item>
        </Row>
      </Form>
      {contextHolder}
    </Modal>
  )
}
export default CreateUserModal

