import React, {useState} from 'react'
import {Card, Checkbox, Col, Layout, Modal, Row, Typography, Upload} from "antd";
import {InboxOutlined} from '@ant-design/icons'
import {useDispatch, useSelector} from "react-redux";
import {getUploadTemplate} from "../../store/products/products.actions";
import {API_URL} from "../../app.settings";
import {selectAccessToken} from "../../store/auth/auth.selector";
import '../Store/Transactions.scss'
import {currencyFormat} from "../../utils/format.utils";

const {Title} = Typography
const {Dragger} = Upload

const UploadProducts = () => {

  const dispatch = useDispatch()
  const download = () => dispatch(getUploadTemplate())
  const token = useSelector(selectAccessToken)
  const [result, setResult] = useState(null)
  const [modal, contextHolder] = Modal.useModal()
  const [created, setCreated] = useState(true)
  const [failed, setFailed] = useState(true)

  const props = {
    name: 'file',
    multiple: false,
    action: API_URL + "/products/upload",
    headers: {"Authorization": `bearer ${token}`},
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    beforeUpload: () => {
      setResult(null)
      setCreated(true)
      setFailed(true)
    },
    onChange(info) {
      const {status, response} = info.file;
      if (status === 'done') {
        setResult(response)
      } else if (status === 'error') {
        modal.error({title: '¡Uh-oh!', content: response?.message || 'Ocurrió un error, por favor intente más tarde'})
      }
    },
  };


  console.log(result)
  return (
    <Layout className='h-100 w-100'>
      <Row>
        <Title>Crear Productos</Title>
      </Row>
      <Row className='h-100 w-100' gutter={[32, 32]}>
        <Card className='bg-card w-100'>
          <Col md={24}>
            <div className='right'>
              <a href='#' onClick={download}>Descargar Plantilla</a>
            </div>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon"><InboxOutlined/></p>
              <p className="ant-upload-text">Haga click o suelte el archivo aca para subirlo</p>
              <p className="ant-upload-hint">
                Aquí podrá crear productos de forma masiva utilizando un archivo de Excel, por favor utilice la
                <span onClick={download}> plantilla adecuada</span> o de lo contrario el proceso fallará.
              </p>
            </Dragger>

            {
              result &&
              <>
                <br/>
                <Row>
                  <Col md={4} className='upload-result-text'>Procesados: {result.processed}</Col>
                  <Col md={4}>
                    <Checkbox checked={created} onChange={() => setCreated(!created)}>
                      <span className='upload-result-text success'>Creados: {result.created}</span>
                    </Checkbox>
                  </Col>
                  <Col md={4}>
                    <Checkbox checked={failed} onChange={() => setFailed(!failed)}>
                      <span className='upload-result-text error'>Fallidos: {result.failed}</span>
                    </Checkbox>
                  </Col>
                </Row>

                <Row className='product-list-header'>
                  <Col md={1}>Fila</Col>
                  <Col md={4}>SKU</Col>
                  <Col md={9}>Nombre</Col>
                  <Col md={2}>Precio</Col>
                  <Col md={2}>Estado</Col>
                  <Col md={6}>Motivo</Col>
                </Row>
                {
                  (
                    result.details || []).filter(detail => (detail.created && created) || (!detail.created && failed))
                    .map((detail, index) =>
                      <Row
                        key={`detail-${index}`}
                        className={`product-list-item ${detail.created ? 'success' : 'error'}`}
                      >
                        <Col md={1}>{detail.row}</Col>
                        <Col md={4}>{detail.sku}</Col>
                        <Col md={9}>{detail.name}</Col>
                        <Col md={2}>{currencyFormat(detail.price)}</Col>
                        <Col md={2}>{detail.created ? 'Creado' : 'Error'}</Col>
                        <Col md={6}>{detail.error}</Col>
                      </Row>
                    )
                }

              </>
            }

          </Col>
        </Card>
      </Row>
      {contextHolder}
    </Layout>
  )
}
export default UploadProducts

