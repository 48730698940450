import React, {useEffect, useState} from 'react'
import {Button, Card, Col, Layout, Pagination, Row, Typography} from "antd";
import './Orders.scss'
import {useDispatch, useSelector} from "react-redux";
import {getOrdersIssues, importOrders} from "../../store/orders/orders.actions";
import {selectOrdersIssues} from "../../store/orders/orders.selector";
import {selectInventoryByBranchAndProductList} from "../../store/products/products.selector";
import {getPrincipalBranch} from "../../store/branches/branches.actions";
import {selectPrincipalBranch} from "../../store/branches/branches.selector";
import {getInventoryByBranchAndProductList} from "../../store/products/products.actions";
import {CheckOutlined, CloseOutlined} from '@ant-design/icons'
import {useIsLoading} from "../../store/app/loading/loading.hooks";
import {actionTypes} from "../../store/orders/orders.types";

const {Title} = Typography;

const EcwidOrdersIssues = () => {

  const dispatch = useDispatch()
  const [order, setOrder] = useState(null)
  const issues = useSelector(selectOrdersIssues)
  const principalBranch = useSelector(selectPrincipalBranch)
  const inventory = useSelector(selectInventoryByBranchAndProductList)
  const [loading, finished] = useIsLoading([actionTypes.IMPORT_ORDERS])

  const init = () => {
    dispatch(getOrdersIssues({page: 1, pageSize: 10}))
    dispatch(getPrincipalBranch())
  }
  useEffect(init, [])

  useEffect(()=>{
    if(finished){
      dispatch(getOrdersIssues({page: 1, pageSize: 10}))
      setOrder(null)
    }
  }, [dispatch, finished])

  const findInventory = (sku, required) => {
    const product = inventory.find(product => product.sku === sku)
    const available = product?.available || 0
    const classes = available >= required ? 'available-green' : 'available-red'
    return (
      <Col md={4} className={classes}>
        {available} {available >= required ?  <CheckOutlined /> : <CloseOutlined />}
      </Col>
    )
  }

  const canBeImported = (order,) => {
    const response =  order.products.reduce( (acc, current) => {
      const product = inventory.find(product => product.sku === current.product.sku)
      const available = (product?.available || 0)
      return available >= current.required
    }, false)
    return !response
  }

  const getDetails = order => {
    setOrder(order)
    dispatch(getInventoryByBranchAndProductList({
      branchId: principalBranch.id,
      products: order.products.map(item => item.product.sku)
    }))
  }

  const importAgain = (orderNumber)=> dispatch(importOrders(orderNumber))

  const onPageChange = (page, pageSize) => dispatch(getOrdersIssues({page, pageSize}))

  return (
    <Layout className={'h-100'}>
      <Title level={2}>Ecwid - Pedidos con problema</Title>
      <Row className={'h-100'} gutter={[32, 32]}>
        <Col md={8}>
          <Card className="bg-card h-100">
            <p>Estos pedidos no se pudieron importar porque hay productos que no tienen suficiente existencia.</p>
            <Row className='orders-list-header'>
              <Col md={14}>Fecha</Col>
              <Col md={10}>Pedido #</Col>
            </Row>
            {
              issues?.items?.map((order, index) =>
                <Row className='order-list-item' key={`order-${index}`} onClick={() => getDetails(order)}>
                  <Col md={14}>{new Date(order.orderDate).toLocaleString('es-GT')}</Col>
                  <Col md={10}>{order.orderNumber}</Col>
                </Row>
              )
            }
            <br/>
            <Pagination
              defaultCurrent={1}
              total={issues?.total || 0}
              onChange={onPageChange}
              current={issues?.page}
              pageSize={issues?.pageSize || 10}
              hideOnSinglePage
            />
          </Card>
        </Col>
        <Col md={16}>
          <Card className={'bg-card h-100'}>
            {
              order == null ?
                <Title level={4}>Click en un pedido para ver más detalles</Title> :
                <>
                  <Row>
                    <Col md={12}>
                      <Title>#{order?.orderNumber}</Title>
                    </Col>
                    <Col md={12} className='right'>
                      <Button
                        size='large'
                        shape="round"
                        type="primary"
                        disabled={canBeImported(order)}
                        loading={loading}
                        onClick={()=>importAgain(order.orderNumber)}
                      >
                        Importar Ahora
                      </Button>
                    </Col>
                  </Row>

                  <Title level={4}>Productos sin existencia suficiente</Title>
                  <Row className='orders-list-header'>
                    <Col md={4}>SKU</Col>
                    <Col md={12}>Producto</Col>
                    <Col md={4}>Requerido</Col>
                    <Col md={4}>Disponible</Col>
                  </Row>
                  {
                    order.products.map((item, index) =>
                      <Row className='order-list-item' key={`order-${index}`}>
                        <Col md={4}>{item.product.sku}</Col>
                        <Col md={12}>{item.product.name}</Col>
                        <Col md={4}>{item.required}</Col>
                        {findInventory(item.product.sku, item.required)}
                      </Row>
                    )
                  }
                </>
            }
          </Card>
        </Col>
      </Row>
    </Layout>
  )
}
export default EcwidOrdersIssues

