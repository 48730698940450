import React, {useEffect, useState} from 'react'
import {Link, useParams} from "react-router-dom"
import {Card, Col, Form, Input, Layout, Pagination, Row, Typography} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getBranchById} from "../../store/branches/branches.actions";
import {clearActionResult} from "../../store/app/app.actions";
import {actionTypes} from "../../store/branches/branches.types";
import {actionTypes as productActionTypes} from "../../store/products/products.types";
import {selectCurrentBranch} from "../../store/branches/branches.selector";
import {getInventoryByBranch} from "../../store/products/products.actions";
import {selectInventoryByBranch} from "../../store/products/products.selector";
import {SearchOutlined, HistoryOutlined, EditOutlined} from '@ant-design/icons'
import './Transactions.scss'
import {currencyFormat} from "../../utils/format.utils";
import {selectUserRoles} from "../../store/auth/auth.selector";

const {Title} = Typography;

const StoreHome = () => {
  const {branchId} = useParams()
  const dispatch = useDispatch()
  const [search, setSearch] = useState("")
  const branch = useSelector(selectCurrentBranch)
  const products = useSelector(selectInventoryByBranch)
  const roles = useSelector(selectUserRoles)
  const isAdmin = roles.find(r => r.name === "Admin") != null
  const init = () => {
    dispatch(getInventoryByBranch({branchId, pageSize: 10}))
    dispatch(getBranchById(branchId))
    return () => {
      dispatch(clearActionResult(actionTypes.GET_BRANCH_BY_ID))
      dispatch(clearActionResult(productActionTypes.GET_INVENTORY_BY_BRANCH))
    }
  }
  useEffect(init, [])

  console.log(branch)

  const onFinish = ({search}) => {
    setSearch(search)
    dispatch(getInventoryByBranch({search, branchId, pageSize: 10}))
  }

  const onPageChange = (page, pageSize) => dispatch(getInventoryByBranch({branchId, page, pageSize, search}))

  return (
    <Layout className={'h-100'}>
      <Row className='align-center'>
        <Col md={16}>
          <Title>{branch?.name}</Title>
        </Col>
        <Col md={4}>
          <Link to={`/tienda/${branchId}/transacciones`}>
            <HistoryOutlined className="big-icon"/>
          </Link>
          {
            isAdmin &&
            <Link to={`/tienda/${branchId}/detalles`}>
              <EditOutlined className="big-icon"/>
            </Link>
          }

        </Col>
        <Col md={4}/>
      </Row>
      <Row className='h-100' gutter={[32, 32]}>
        <Col md={18}>
          <Card className="bg-card h-100">
            <Form onFinish={onFinish}>
              <Form.Item name='search'>
                <Input
                  placeholder="Buscar Productos"
                  prefix={<SearchOutlined/>}
                />
              </Form.Item>
            </Form>

            <Row className='product-list-header'>
              <Col md={4}>SKU</Col>
              <Col md={12}>Producto</Col>
              <Col md={3} className='center'>Precio</Col>
              <Col md={1}/>
              <Col md={2} className='center'>Disponible</Col>
            </Row>
            {
              (products?.items || []).map((product, index) =>
                <Row className='product-list-item' key={`store-products-${index}`}>
                  <Col md={4}>{product.sku}</Col>
                  <Col md={12}>{product.name}</Col>
                  <Col md={1}>Q. </Col>
                  <Col md={2} className='right'>{currencyFormat(product.price) || '- -'}</Col>
                  <Col md={1}/>
                  <Col md={2} className='center'>{product.available}</Col>
                </Row>
              )
            }
            <br/>
            <Pagination
              defaultCurrent={1}
              total={products?.total || 0}
              onChange={onPageChange}
              current={products?.page}
              pageSize={products?.pageSize || 10}
              hideOnSinglePage
            />
          </Card>
        </Col>
        {
          !branch?.deleted &&
          <Col md={6} className='transaction-vertical-buttons'>
            <Link to={`/tienda/${branchId}/ventas`} className="custom-card button">Ventas</Link>
            <Link to={`/tienda/${branchId}/retornos`} className="custom-card button">Devoluciones</Link>
            <Link to={`/tienda/${branchId}/transferencias`} className="custom-card button">Transferencias</Link>
            <Link to={`/tienda/${branchId}/entradas`} className="custom-card button">Entradas</Link>
            <Link to={`/tienda/${branchId}/salidas`} className="custom-card button">Salidas</Link>
          </Col>
        }

      </Row>
    </Layout>
  )
}
export default StoreHome

