import {$http, downloadFile, uploadFile} from "../../utils/http.utils";

class ProductsService {
  allProducts =
    ({page = 1, pageSize = 25, search}) =>
      $http.get('/products', {params: {page, pageSize, search}})

  inventoryByBranch =
    ({page = 1, pageSize = 25, branchId, search}) =>
      $http.get(`/inventory/${branchId}`, {params: {page, pageSize, search}})

  inventoryByBranchAndSKU =
    (branchId, sku) => $http.get(`/inventory/${branchId}/${sku}`)

  createProduct =
    ({sku, name, description, price}) =>
      $http.post(`/products`, {sku, name, description, price})

  getUploadTemplate = ()=> downloadFile("/products/upload/template")

  uploadProducts = ({file})=> uploadFile("/products/upload", file)
}

export default new ProductsService()
