import React, {useEffect, useState} from 'react'
import {Card, Checkbox, Col, Layout, Modal, Row, Typography, Upload} from "antd";
import {InboxOutlined} from '@ant-design/icons'
import {useDispatch, useSelector} from "react-redux";
import {API_URL} from "../../app.settings";
import {selectAccessToken} from "../../store/auth/auth.selector";
import '../Store/Transactions.scss'
import {currencyFormat} from "../../utils/format.utils";
import {Link, useParams} from "react-router-dom";
import {selectCurrentBranch} from "../../store/branches/branches.selector";
import {getBranchById} from "../../store/branches/branches.actions";
import {clearActionResult} from "../../store/app/app.actions";
import {actionTypes} from "../../store/branches/branches.types";
import {downloadTransactionPDF, getInventoryUploadTemplate} from "../../store/inventory/inventory.actions";

const {Title} = Typography
const {Dragger} = Upload

const UploadInventory = () => {
  const {branchId} = useParams()
  const dispatch = useDispatch()
  const download = () => dispatch(getInventoryUploadTemplate())
  const token = useSelector(selectAccessToken)
  const [result, setResult] = useState(null)
  const [modal, contextHolder] = Modal.useModal()
  const [created, setCreated] = useState(true)
  const [failed, setFailed] = useState(true)
  const branch = useSelector(selectCurrentBranch)

  const init = () => {
    dispatch(getBranchById(branchId))
    return () => clearActionResult(actionTypes.GET_BRANCH_BY_ID)
  }
  useEffect(init, [])

  const props = {
    name: 'file',
    multiple: false,
    action: API_URL + `/inventory/${branchId}/upload`,
    headers: {"Authorization": `bearer ${token}`},
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    beforeUpload: () => {
      setResult(null)
      setCreated(true)
      setFailed(true)
    },
    onChange(info) {
      const {status, response} = info.file;
      if (status === 'done') {
        setResult(response)
        if (response.transactionId) {
          dispatch(downloadTransactionPDF({branchId, transactionId: response.transactionId}))
        }
      } else if (status === 'error') {
        modal.error({title: '¡Uh-oh!', content: response?.message || 'Ocurrió un error, por favor intente más tarde'})
      }
    },
  };


  console.log(result)
  return (
    <Layout className='h-100 w-100'>
      <Row>
        <Link to={`/tienda/${branchId}`}>
          <Title>{branch?.name}</Title>
        </Link>
      </Row>
      <Row className='h-100 w-100' gutter={[32, 32]}>
        <Card className='bg-card w-100'>
          <Col md={24}>
            <div className='right'>
              <a href='#' onClick={download}>Descargar Plantilla</a>
            </div>
            <Dragger {...props}>
              <p className="ant-upload-drag-icon"><InboxOutlined/></p>
              <p className="ant-upload-text">Haga click o suelte el archivo aca para subirlo</p>
              <p className="ant-upload-hint">
                Aquí podrá subir su inventario de forma masiva utilizando un archivo de Excel, por favor utilice la
                plantilla adecuada o de lo contrario el proceso fallará.
              </p>
            </Dragger>

            {
              result &&
              <>
                <br/>
                <Row>
                  <Col md={4} className='upload-result-text'>Procesados: {result.processed}</Col>
                  <Col md={4}>
                    <Checkbox checked={created} onChange={() => setCreated(!created)}>
                      <span className='upload-result-text success'>Exitosos: {result.processed - result.failed}</span>
                    </Checkbox>
                  </Col>
                  <Col md={4}>
                    <Checkbox checked={failed} onChange={() => setFailed(!failed)}>
                      <span className='upload-result-text error'>Fallidos: {result.failed}</span>
                    </Checkbox>
                  </Col>
                </Row>

                <Row className='product-list-header'>
                  <Col md={1}>Fila</Col>
                  <Col md={4}>SKU</Col>
                  <Col md={8}>Nombre</Col>
                  <Col md={2}>Anterior</Col>
                  <Col md={2}>Agregado</Col>
                  <Col md={2}>Actual</Col>
                  <Col md={2}>Estado</Col>
                  <Col md={3}>Motivo</Col>
                </Row>
                {
                  (
                    result.details || []).filter(detail => (detail.success && created) || (!detail.success && failed))
                    .map((detail, index) =>
                      <Row
                        key={`detail-${index}`}
                        className={`product-list-item ${detail.success ? 'success' : 'error'}`}
                      >
                        <Col md={1}>{detail.row}</Col>
                        <Col md={4}>{detail.sku}</Col>
                        <Col md={8}>{detail.name || ''}</Col>
                        <Col md={2}>{detail.old}</Col>
                        <Col md={2}>{detail.added}</Col>
                        <Col md={2}>{detail.total}</Col>
                        <Col md={2}>{detail.success ? 'Exitoso' : 'Error'}</Col>
                        <Col md={3}>{detail.error}</Col>
                      </Row>
                    )
                }

              </>
            }

          </Col>
        </Card>
      </Row>
      {contextHolder}
    </Layout>
  )
}
export default UploadInventory

