import React, {useState} from 'react'
import {Button, Card, Col, DatePicker, Row, Select, Typography} from "antd";
import {useIsLoading} from "../../store/app/loading/loading.hooks";
import {actionTypes} from "../../store/inventory/inventory.types";
import {useDispatch, useSelector} from "react-redux";
import {
  downloadGeneralTransactions,
  getCompareReport,
  getInventoryReport,
  getSalesReport
} from "../../store/inventory/inventory.actions";
import './Reports.scss'
import {selectAllBranches} from "../../store/branches/branches.selector";
import moment from "moment";

const {Title} = Typography;
const {Option} = Select;
const dateFormat = 'DD/MM/YYYY';

const Reports = () => {

  const dispatch = useDispatch()
  const [inventoryReportLoading] = useIsLoading([actionTypes.INVENTORY_REPORT])
  const [inventoryCompareReportLoading] = useIsLoading([actionTypes.INVENTORY_COMPARE_REPORT])
  const [salesReportLoading] = useIsLoading([actionTypes.SALES_REPORT])
  const [transactionsReportLoading] = useIsLoading([actionTypes.DOWNLOAD_GENERAL_TRANSACTIONS])
  const branches = useSelector(selectAllBranches)

  const [branch1, setBranch1] = useState()
  const [branch2, setBranch2] = useState()

  const [salesFrom, setSalesFrom] = useState(moment())
  const [salesTo, setSalesTo] = useState(moment())

  const [transactionsFrom, setTransactionsFrom] = useState(moment())
  const [transactionsTo, setTransactionsTo] = useState(moment())

  const downloadInventoryReport = () => dispatch(getInventoryReport())
  const downloadInventoryCompareReport = () => dispatch(getCompareReport({branch1, branch2}))
  const downloadSalesReport = ()=>{
    dispatch( getSalesReport({
      from: salesFrom.unix(),
      to: salesTo.unix(),
    }))
  }
  const downloadTransactionsReport = ()=>{
    dispatch(downloadGeneralTransactions({
      sales:true,
      returns:true,
      reserves:true,
      ingress:true,
      egress:true,
      transfers:true,
      receipts:true,
      from: transactionsFrom?.unix(),
      to: transactionsTo?.unix(),
    }))
  }

  return (
    <Card className='bg-card h-100'>
      <Row className='report-row'>
        <Col>
          <Title level={2}>Reporte De Inventarios</Title>
          <Button
            size='large'
            shape="round"
            type="primary"
            loading={inventoryReportLoading}
            onClick={downloadInventoryReport}
          >
            Descargar
          </Button>
        </Col>
      </Row>
      <hr/>
      <Row className='report-row'>
        <Col md={24}>
          <Title level={2}>Cuadre General De Caja</Title>
          <Row>
            <Col md={6}>
              <Title level={4}>Del:</Title>
              <DatePicker type='date' format={dateFormat} defaultValue={moment()} onChange={d => setSalesFrom(d)}/>
            </Col>
            <Col md={6}>
              <Title level={4}>Al:</Title>
              <DatePicker type='date' format={dateFormat} defaultValue={moment()} onChange={d => setSalesTo(d)}/>
            </Col>
          </Row>
          <br/>
          <Button
            size='large'
            shape="round"
            type="primary"
            loading={salesReportLoading}
            onClick={downloadSalesReport}
          >
            Descargar
          </Button>
        </Col>
      </Row>
      {
        branches.total > 1 &&
        <>
          <hr/>
          <Row className='report-row'>
            <Col md={24}>
              <Title level={2}>Comparar Inventarios</Title>
              <Row className='w-100' gutter={[32, 32]}>
                <Col md={6}>
                  <Title level={4}>Bodega 1</Title>
                  <Select onSelect={val => setBranch1(val)} className='w-100'>
                    {
                      branches.items.map(branch =>
                        <Option value={branch.id} key={`branches-${branch.id}`}>{branch.name}</Option>
                      )
                    }
                  </Select>
                </Col>
                <Col md={6}>
                  <Title level={4}>Bodega 2</Title>
                  <Select onSelect={val => setBranch2(val)} className='w-100'>
                    {
                      branches.items.map(branch =>
                        <Option value={branch.id} key={`branches-${branch.id}`}>{branch.name}</Option>
                      )
                    }
                  </Select>
                </Col>
              </Row>

              <Button
                size='large'
                shape="round"
                type="primary"
                loading={inventoryCompareReportLoading}
                onClick={downloadInventoryCompareReport}
              >
                Descargar
              </Button>
            </Col>
          </Row>
        </>
      }
      <Row className='report-row'>
        <Col md={24}>
          <Title level={2}>Reporte General De Transacciones</Title>
          <Row>
            <Col md={6}>
              <Title level={4}>Del:</Title>
              <DatePicker type='date' format={dateFormat} defaultValue={moment()} onChange={d => setTransactionsFrom(d)}/>
            </Col>
            <Col md={6}>
              <Title level={4}>Al:</Title>
              <DatePicker type='date' format={dateFormat} defaultValue={moment()} onChange={d => setTransactionsTo(d)}/>
            </Col>
          </Row>
          <br/>
          <Button
            size='large'
            shape="round"
            type="primary"
            loading={transactionsReportLoading}
            onClick={downloadTransactionsReport}
          >
            Descargar
          </Button>
        </Col>
      </Row>
    </Card>
  )
}
export default Reports

