import UsersService from './users.service'
import {executeAction} from "../app/app.actions";
import {actionTypes} from "./users.types";
import {getCurrentUser} from "../auth/auth.actions";

export const getAllUsers = ({page = 1, pageSize = 10, active = true, deleted = false} = {}) => dispatch => {
  const process = () => UsersService.getUsers({page, pageSize, active, deleted})
  dispatch(executeAction(actionTypes.GET_USERS, process))
}

export const updateUser = ({userId, firstName, lastName, role}) => dispatch => {
  const process = async () => {
    await UsersService.updateUser({userId, firstName, lastName, role})
    return UsersService.getUsers({})
  }
  dispatch(executeAction(actionTypes.UPDATE_USER, process))
}

export const editProfile = ({userId, firstName, lastName}) => dispatch => {
  const process = async () => {
    await UsersService.updateUser({userId, firstName, lastName})
    dispatch(getCurrentUser())
  }
  dispatch(executeAction(actionTypes.EDIT_PROFILE, process))
}

export const toggleUser = ({userId, page, pageSize=10, active, deleted}) => dispatch => {
  const process = async () => {
    await UsersService.toggleUser({userId})
    return UsersService.getUsers({page, pageSize, active, deleted})
  }
  dispatch(executeAction(actionTypes.TOGGLE_USER, process))
}

export const createUser = ({email, firstName, lastName, roles}) => dispatch => {
  const process = async () => {
    const result = await UsersService.createUser({email, firstName, lastName, roles})
    dispatch(getAllUsers({}))
    return result.data
  }
  dispatch(executeAction(actionTypes.CREATE_USER, process))
}

export const changePassword = ({oldPassword, newPassword}) => dispatch => {
  const process = ()=> UsersService.changePassword({oldPassword, newPassword})
  dispatch(executeAction(actionTypes.CHANGE_PASSWORD, process))
}
