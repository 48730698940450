import {actionTypes} from './branches.types'
import {extract, successState} from '../app/app.actions'

const initialState = {
  allBranches: {items: []},
  currentBranch: null,
  principal: null,
  userBranches: [],
  users:[]
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_ALL_BRANCHES):
      return {
        ...state,
        allBranches: extract(action.data,[])
      };
    case successState(actionTypes.CREATE_BRANCH):
    case successState(actionTypes.GET_BRANCHES_BY_USER):
      return {
        ...state,
        userBranches: extract(action.data, [])
      };
    case successState(actionTypes.GET_BRANCH_BY_ID):
    case successState(actionTypes.ACTIVATE_BRANCH):
    case successState(actionTypes.DEACTIVATE_BRANCH):
      return {
        ...state,
        currentBranch: action.data
      };
    case successState(actionTypes.GET_PRINCIPAL_BRANCH):
    case successState(actionTypes.SET_PRINCIPAL_BRANCH):
      return {
        ...state,
        principal: action.data
      };
    case successState(actionTypes.GET_ASSIGNED_USERS):
    case successState(actionTypes.ADD_USER_TO_BRANCH):
    case successState(actionTypes.REMOVE_USER_FROM_BRANCH):
      return {
        ...state,
        users: extract(action.data, [])
      };
    case successState(actionTypes.CLEAR_BRANCHES):
      return initialState
    default:
      return state || initialState
  }
}

export default reducer
