import React, {useEffect, useState} from 'react'
import {Button, Card, Col, DatePicker, Layout, Row, Typography} from "antd";
import {useIsLoading} from "../../store/app/loading/loading.hooks";
import {actionTypes} from "../../store/inventory/inventory.types";
import {useDispatch, useSelector} from "react-redux";
import {getSalesReport} from "../../store/inventory/inventory.actions";
import {selectCurrentBranch} from "../../store/branches/branches.selector";
import moment from "moment";
import {useParams} from "react-router-dom";
import {getBranchById} from "../../store/branches/branches.actions";
import {selectUserRoles} from "../../store/auth/auth.selector";

const {Title} = Typography;
const dateFormat = 'DD/MM/YYYY';

const StoreReports = () => {
  const {branchId} = useParams()
  const roles = useSelector(selectUserRoles)
  const dispatch = useDispatch()
  const [salesReportLoading] = useIsLoading([actionTypes.SALES_REPORT])
  const branch = useSelector(selectCurrentBranch)
  const init = () => {
    dispatch(getBranchById(branchId))
  }
  useEffect(init, [])

  console.log(roles)
  const [salesFrom, setSalesFrom] = useState(moment())
  const [salesTo, setSalesTo] = useState(moment())

  const downloadSalesReport = () => {
    dispatch(getSalesReport({
      from: salesFrom.unix(),
      to: salesTo.unix(),
      branchId,
    }))
  }

  return (
    <Layout className='h-100'>
      <Row justify='space-between'>
        <Title>{branch?.name} - Transacciones</Title>
      </Row>
      <Row className='h-100' gutter={[32, 32]}>
        <Col md={24}>
          <Card className='bg-card h-100'>
            <Row className='report-row'>
              <Col md={24}>
                <Title level={2}>Cuadre de caja</Title>
                <Row>
                  <Col md={6}>
                    <Title level={4}>Del:</Title>
                    <DatePicker type='date' format={dateFormat} defaultValue={moment()}
                                onChange={d => setSalesFrom(d)}/>
                  </Col>
                  <Col md={6}>
                    <Title level={4}>Al:</Title>
                    <DatePicker type='date' format={dateFormat} defaultValue={moment()} onChange={d => setSalesTo(d)}/>
                  </Col>
                </Row>
                <br/>
                <Button
                  size='large'
                  shape="round"
                  type="primary"
                  loading={salesReportLoading}
                  onClick={downloadSalesReport}
                >
                  Descargar
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Layout>

  )
}
export default StoreReports

