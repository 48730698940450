import React, {useEffect, useState} from 'react'
import {Button, Card, Checkbox, Col, Form, Input, Layout, Modal, Pagination, Radio, Row, Typography} from "antd";
import './Users.scss'
import {useDispatch, useSelector} from "react-redux";
import {getAllUsers, toggleUser, updateUser} from "../../store/users/users.actions";
import {selectAllUsers} from "../../store/users/users.selector";
import {CheckOutlined, CloseOutlined, UserAddOutlined} from '@ant-design/icons'
import {selectUser} from "../../store/auth/auth.selector";
import CreateUserModal from "./CreateUserModal";
import {playErrorSound} from "../../utils/sound.utils";
import {useIsLoading} from "../../store/app/loading/loading.hooks";
import {actionTypes} from "../../store/users/users.types";

const {Title} = Typography;

const Users = () => {

  const dispatch = useDispatch();
  const me = useSelector(selectUser)
  const users = useSelector(selectAllUsers)
  const filteredUsers = (users.items || []).filter(user => user.id !== me.id)
  const [active, setActive] = useState(true)
  const [deleted, setDeleted] = useState(false)
  const [current, setCurrent] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [modal, contextHolder] = Modal.useModal()

  useEffect(() => {
    setCurrent(null)
    dispatch(getAllUsers({page: users.page, pageSize: users.pageSize || 10, active, deleted}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active, deleted])

  const onPageChange = (page, pageSize) => {
    setCurrent(null)
    dispatch(getAllUsers({page, pageSize, active, deleted}))
  }

  const isAdmin = current?.roles.find(r => r.name === "Admin") != null
  const disabled = current == null;
  const currentChanged = (property, value) => {
    setCurrent({...current, [property]: value})
  }

  const saveUser = () => {
    if (!current.firstName || !current.lastName) {
      modal.error({
        title: "¡Uh-oh!",
        content: "Todos los campos son obligatorios"
      })
      playErrorSound()
    } else {
      dispatch(updateUser({
        userId: current.id,
        firstName: current.firstName,
        lastName: current.lastName,
        role: current.roles[0].name
      }))
    }
  }

  const [loading, finished] = useIsLoading([actionTypes.TOGGLE_USER])
  useEffect(() => {
    if (finished) {
      setCurrent({...current, deleted: !current.deleted})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finished])

  const toggle = () => {
    dispatch(toggleUser({page: users.page, pageSize: users.pageSize, active, deleted, userId: current.id}))
  }

  return (
    <Layout className='h-100'>
      <Row className='align-center'>
        <Col md={15}>
          <Title>Usuarios</Title>
        </Col>
        <Col md={3}>
          <UserAddOutlined className="big-icon button" onClick={() => setShowModal(true)}/>
        </Col>
        <Col md={4}/>
      </Row>
      <Row gutter={[32, 32]} className='h-100'>
        <Col md={16}>
          <Card className="bg-card h-100">
            <Row justify={'space-between'}>
              <Title level={2}>Listado</Title>
              <div>
                <Checkbox checked={active} onChange={() => setActive(!active)}>
                  Activos
                </Checkbox>
                <Checkbox checked={deleted} onChange={() => setDeleted(!deleted)}>
                  Inactivos
                </Checkbox>
              </div>
            </Row>
            <Row className='user-list-header'>
              <Col md={9}>Email</Col>
              <Col md={10}>Nombre</Col>
              <Col md={4}>Rol</Col>
              <Col/>
            </Row>
            {
              filteredUsers.map(user =>
                <Row className='user-list-item' key={`user-${user.id}`} onClick={() => setCurrent(user)}>
                  <Col md={9}>{user.email}</Col>
                  <Col md={10}>{user.firstName} {user.lastName}</Col>
                  <Col md={4}>{user?.roles?.map(r => r.name).join()}</Col>
                  <Col md={1}>
                    {user?.deleted ? <CloseOutlined className='red'/> : <CheckOutlined className='green'/>}
                  </Col>
                </Row>
              )
            }
            <br/>
            <Pagination
              defaultCurrent={1}
              total={users?.total || 0}
              onChange={onPageChange}
              current={users?.page}
              pageSize={users?.pageSize || 10}
              hideOnSinglePage
            />
          </Card>
        </Col>
        <Col md={8}>
          <Card className="bg-card">
            <Title level={3}>Detalles</Title>
            <Form layout={'vertical'}>
              <Form.Item label="Nombre">
                <Input
                  disabled={disabled}
                  value={current?.firstName}
                  onChange={e => currentChanged("firstName", e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Apellido">
                <Input
                  disabled={disabled}
                  value={current?.lastName}
                  onChange={e => currentChanged("lastName", e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Roles">
                <Radio
                  checked={isAdmin}
                  disabled={disabled}
                  onChange={() => currentChanged("roles", [{name: "Admin"}])}
                >
                  Admin
                </Radio>
                <Radio
                  checked={!isAdmin}
                  disabled={disabled}
                  onChange={() => currentChanged("roles", [{name: "Empleado"}])}
                >
                  Empleado
                </Radio>
              </Form.Item>
            </Form>
            <Row justify={"space-between"}>
              {
                current?.deleted ?
                  <Button
                    shape={"round"}
                    type={"primary"}
                    onClick={toggle}
                    loading={loading}
                    className={'bg-green'}
                    disabled={current == null}
                  >
                    Activar
                  </Button> :
                  <Button
                    type={"danger"}
                    shape={"round"}
                    onClick={toggle}
                    loading={loading}
                    disabled={disabled}
                  >
                    Desactivar
                  </Button>
              }
              {/*<Button shape="round" disabled={current == null}>Nueva Contraseña</Button>*/}
              <Button type="primary" shape="round" disabled={disabled} onClick={saveUser}>Guardar</Button>

            </Row>
          </Card>
        </Col>
      </Row>
      <CreateUserModal visible={showModal} onCancel={() => setShowModal(false)}/>
      {contextHolder}
    </Layout>
  )
}
export default Users

