export const actionTypes = {
  GET_ALL_PRODUCTS: 'products/all/get',
  GET_INVENTORY_BY_BRANCH: 'products/inventory/get',
  GET_INVENTORY_BY_BRANCH_AND_SKU: 'products/inventory/sku/get',
  GET_INVENTORY_BY_BRANCH_AND_PRODUCT_LIST: 'products/inventory/list/get',
  CREATE_PRODUCT: '/products/crate',
  DOWNLOAD_TEMPLATE: 'products/upload/template',
  UPLOAD_PRODUCTS: 'products/upload',
  CLEAR_PRODUCTS: 'products/clear'
}
