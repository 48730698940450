import {executeAction} from "../app/app.actions";
import {actionTypes} from "./auth.types";
import {actionTypes as inventoryActionTypes} from "../inventory/inventory.types";
import {actionTypes as branchesActionTypes} from "../branches/branches.types";
import {actionTypes as productsActionTypes} from "../products/products.types";
import {actionTypes as ordersActionTypes} from "../orders/orders.types";
import {actionTypes as userActionTypes} from '../users/users.types';

export const logout = ()=> dispatch => {
  dispatch(executeAction(actionTypes.LOGOUT))
  dispatch(executeAction(inventoryActionTypes.CLEAR_INVENTORY))
  dispatch(executeAction(branchesActionTypes.CLEAR_BRANCHES))
  dispatch(executeAction(productsActionTypes.CLEAR_PRODUCTS))
  dispatch(executeAction(ordersActionTypes.CLEAR_ORDERS))
  dispatch(executeAction(userActionTypes.CLEAR_USERS))
}
