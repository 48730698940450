import {$http} from "../../utils/http.utils";

class BranchesService {
  allBranches = () => $http.get('/branches')
  byId = (id) => $http.get(`/branches/${id}`)
  principal = () => $http.get('/branches/principal')
  byUser = (userId) => $http.get(`/users/${userId}/branches`)
  create = ({name, access, principal}) => $http.post("/branches", {name, access, principal})
  assignedUsers = ({branchId}) => $http.get(`/branches/${branchId}/users`)
  assignUser = ({branchId, userId}) => $http.put(`/branches/${branchId}/users/${userId}`)
  removeUser = ({branchId, userId}) => $http.delete(`/branches/${branchId}/users/${userId}`)
  activate = ({branchId}) => $http.put(`/branches/${branchId}`)
  deactivate = ({branchId}) => $http.delete(`/branches/${branchId}`)
  makePrincipal = ({branchId}) => $http.post(`/branches/principal/${branchId}`)
}

export default new BranchesService()
