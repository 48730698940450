import {actionTypes} from './users.types'
import {successState} from '../app/app.actions'

const initialState = {
  allUsers: {items: [], total: 0, page: 1, pageSize: 10},
  currentPwd: null,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case successState(actionTypes.GET_USERS):
    case successState(actionTypes.UPDATE_USER):
    case successState(actionTypes.TOGGLE_USER):
      return {
        ...state,
        allUsers: action.data
      };
    case successState(actionTypes.CREATE_USER):
      return {
        ...state,
        currentPwd: action.data
      };
    case successState(actionTypes.CLEAR_USERS):
      return initialState;
    default:
      return state || initialState
  }
}

export default reducer
