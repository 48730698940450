import React, {useCallback, useEffect} from 'react'
import {Modal, Form, Button, Input, Typography, Row} from "antd";
import {inputRequired} from "../../utils/forms.utils";
import {useDispatch, useSelector} from "react-redux";
import {createBranch} from "../../store/branches/branches.actions";
import {selectUser} from "../../store/auth/auth.selector";
import {useIsLoading} from "../../store/app/loading/loading.hooks";
import {actionTypes} from "../../store/branches/branches.types";
import {useHasErrors} from "../../store/app/error/error.hooks";
import {playErrorSound} from "../../utils/sound.utils";

const {Title} = Typography;

const CreateBranchModal = ({visible, onCancel}) => {

  const dispatch = useDispatch()
  const user = useSelector(selectUser)
  const [form] = Form.useForm()
  const [loading, finished] = useIsLoading([actionTypes.CREATE_BRANCH])
  const [APIError, hasError] = useHasErrors([actionTypes.CREATE_BRANCH])
  const [modal, contextHolder] = Modal.useModal()

  const cancel = useCallback(() => {
    form.resetFields()
    onCancel()
  },[form, onCancel])

  useEffect(()=>{
    if(finished){
      if(hasError){
        playErrorSound()
        modal.error({
          title:'¡Uh-Oh!',
          content: APIError.message
        })
      } else {
        modal.success({
          title:'¡Éxito!',
          content: "¡Bodega creada exitosamente!",
          onOk: cancel
        })
      }
    }
  },[APIError.message, cancel, finished, hasError, modal])

  const finish = ({name}) => {
    dispatch(createBranch({name, access: [user.id], principal: false}))
  }

  return (
    <Modal visible={visible} onCancel={cancel} destroyOnClose maskClosable={false} footer={null}>
      <Title level={3}>Crear Bodega</Title>
      <Form layout='vertical' onFinish={finish} form={form}>
        <Form.Item name='name' label='Nombre' rules={[inputRequired]}>
          <Input type='search' autoComplete='off'/>
        </Form.Item>
        <Row justify={'end'}>
          <Form.Item>
            <Button type="primary" htmlType="submit" size={'large'} shape={"round"} loading={loading}>
              Guardar
            </Button>
          </Form.Item>
        </Row>
      </Form>
      {contextHolder}
    </Modal>
  )
}
export default CreateBranchModal

