import React, {useEffect, useState} from 'react'
import {Layout} from 'antd';
import SideMenu from "./SideMenu";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {Route, Switch} from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import StoreHome from "../pages/Store/StoreHome";
import Sales from "../pages/Store/Sales";
import Returns from "../pages/Store/Returns";
import SpecialIngress from "../pages/Store/SpecialIngress";
import SpecialEgress from "../pages/Store/SpecialEgress";
import EcwidOrders from "../pages/Orders/EcwidOrders";
import EcwidOrdersIssues from "../pages/Orders/EcwidOrdersIssues";
import Reports from "../pages/Reports/Reports";
import TransfersDashboard from "../pages/Store/TransfersDashboard";
import TransferCreate from "../pages/Store/TransferCreate";
import TransferReceive from "../pages/Store/TransferReceive";
import Transactions from "../pages/Store/Transactions";
import Users from "../pages/Users/Users";
import StoreReports from "../pages/Store/StoreReports";
import Profile from "../pages/Profile/Profile";
import Products from "../pages/Products/Products";
import StoreDetails from "../pages/Store/StoreDetails";
import UploadProducts from "../pages/Products/UploadProducts";
import UploadInventory from "../pages/Store/UploadInventory";

const {Content, Sider} = Layout;
const ProtectedRoutes = () => {

  const [collapsed, setCollapsed] = useState(true)
  const [sidebarWidth, setSidebarWidth] = useState(120)
  const breakpoint = useBreakpoint()

  useEffect(() => {
    if (breakpoint.xs) {
      setSidebarWidth(0)
    } else {
      setSidebarWidth(80)
    }
  }, [breakpoint.xs])

  return (
    <Layout className='h-screen'>
      <Sider theme={"light"} collapsible collapsedWidth={sidebarWidth} width={250} collapsed={collapsed}
             onCollapse={setCollapsed}>
        <SideMenu/>
      </Sider>
      <Layout>
        <Content>
          <div className="main-layout h-100" style={{padding: 24, minHeight: 360}}>
            <Switch>
              <Route exact path={'/'}>
                <Dashboard/>
              </Route>
              <Route exact path={'/tienda/:branchId'}>
                <StoreHome/>
              </Route>
              <Route exact path={'/tienda/:branchId/transacciones'}>
                <Transactions/>
              </Route>
              <Route exact path={'/tienda/:branchId/detalles'}>
                <StoreDetails/>
              </Route>
              <Route exact path={'/tienda/:branchId/detalles/inventario'}>
                <UploadInventory/>
              </Route>
              <Route exact path={'/tienda/:branchId/transacciones/reportes'}>
                <StoreReports/>
              </Route>
              <Route exact path={'/tienda/:branchId/ventas'}>
                <Sales/>
              </Route>
              <Route exact path={'/tienda/:branchId/retornos'}>
                <Returns/>
              </Route>
              <Route exact path={'/tienda/:branchId/entradas'}>
                <SpecialIngress/>
              </Route>
              <Route exact path={'/tienda/:branchId/salidas'}>
                <SpecialEgress/>
              </Route>

              <Route exact path={'/tienda/:branchId/transferencias'}>
                <TransfersDashboard/>
              </Route>
              <Route exact path={'/tienda/:branchId/transferencias/nueva'}>
                <TransferCreate/>
              </Route>
              <Route exact path={'/tienda/:branchId/transferencias/recibir/:transferId'}>
                <TransferReceive/>
              </Route>


              <Route exact path={'/productos'}>
                <Products/>
              </Route>
              <Route exact path={'/productos/crear'}>
                <UploadProducts/>
              </Route>

              <Route exact path={'/ecwid/ordenes'}>
                <EcwidOrders/>
              </Route>
              <Route exact path={'/ecwid/ordenes/problemas'}>
                <EcwidOrdersIssues/>
              </Route>

              <Route exact path={'/usuarios'}>
                <Users/>
              </Route>

              <Route exact path={'/reportes'}>
                <Reports/>
              </Route>

              <Route exact path={'/perfil'}>
                <Profile/>
              </Route>
            </Switch>
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}
export default ProtectedRoutes

